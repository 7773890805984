/* eslint-disable import/prefer-default-export */
import { getRecommendationsWithTrips } from 'utils/userPreferences';
import {
  SET_FREQUENT_PASSENGERS,
  SET_RECOMMENDED_TRIPS,
  SET_RECOMMENDED_TRIPS_LOADING,
  SET_FREQUENT_PASSENGERS_LOADING,
} from '../constants/ActionTypes';
import userPreferencesService from '../services/userPreferences';
import errorMonitoring from '../errorMonitoring';

/**
 * Set recommended trips
 * @param {Object} params - Object params
 * @param {string} params.searchId - Search id
 * @param {Array} params.trips - Trips
 * @returns
 */
export const setRecommendedTrips = ({ searchId, trips }) => ({
  type: SET_RECOMMENDED_TRIPS,
  searchId,
  trips,
});

/**
 * Set recommended trips loading
 * @param {Boolean} isLoading - Whether the recommended trips are loading or not
 * @returns
 */
export const setRecommendedTripsLoading = (isLoading) => {
  return {
    type: SET_RECOMMENDED_TRIPS_LOADING,
    isLoading,
  };
};

/**
 * Get recommended trips for a search
 * @param {Object} params - Object params
 * @param {string} params.searchId - Search id
 * @param {Array} params.searchTrips - Search trips
 */
export const setUpRecommendedTrips = ({ searchId, searchTrips }) => {
  return async (dispatch) => {
    dispatch(setRecommendedTripsLoading(true));
    try {
      const response = await userPreferencesService.getRecommendedTrips({ searchId });
      const { trips: recommendedTrips } = response;

      if (recommendedTrips && Object.keys(recommendedTrips).length) {
        const recommendedTripsObject = getRecommendationsWithTrips({
          recommendedTrips,
          searchTrips,
        });

        dispatch(
          setRecommendedTrips({
            searchId,
            trips: recommendedTripsObject,
          }),
        );
      } else {
        dispatch(setRecommendedTripsLoading(false));
      }
    } catch (error) {
      errorMonitoring.notify({ error, info: 'Error getting recommended trips' });
      dispatch(setRecommendedTripsLoading(false));
    }
  };
};

/**
 * Set frequent passengers in the store
 * @param {Object} passengers - Passenger information
 * @returns {Object} Action object
 */
export const setFrequentPassengers = (passengers) => ({
  type: SET_FREQUENT_PASSENGERS,
  passengers,
});

/**
 * Set loading state for frequent passengers
 * @param {boolean} isLoading - Loading state
 * @returns {Object} Action object
 */
export const setFrequentPassengersLoading = (isLoading) => ({
  type: SET_FREQUENT_PASSENGERS_LOADING,
  isLoading,
});

/**
 * Fetch and set up frequent passengers in the store
 * @returns {Function} Thunk function that dispatches actions
 */
export const setUpFrequentPassengers = () => {
  return async (dispatch) => {
    dispatch(setFrequentPassengersLoading(true));
    try {
      const response = await userPreferencesService.getFrequentPassengers();
      const { passengers } = response;

      if (passengers && passengers.length) {
        dispatch(setFrequentPassengers(passengers));
      }
    } catch (error) {
      errorMonitoring.notify({ error, info: 'Error getting frequent passengers' });
    } finally {
      dispatch(setFrequentPassengersLoading(false));
    }
  };
};
