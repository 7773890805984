import redeEngine from '../../../engines/rede';

/**
 * RedeStrategy function to create payload for Rede payment method.
 * @returns {Object} An object containing the createPayload function.
 */
const RedeStrategy = () => {
  /**
   * Creates a payload for the Rede payment method.
   * @param {Object} cardInfo - The card information.
   * @param {Object} options - Additional options.
   * @returns {Promise<Object>} The payload for the Rede payment method.
   */
  const createPayload = async (cardInfo) => {
    const { cardNumber, holderName, expirationMonth, expirationYear, cardBrand, cvv2 } = cardInfo;
    const token = await redeEngine.tokenizeCard({
      holderName,
      cardNumber,
      expirationMonth,
      expirationYear,
      cardBrand,
      cvv: cvv2,
    });
    const firstDigits = cardNumber.slice(0, 6);
    const lastDigits = cardNumber.slice(-4);
    return {
      card_number: `${firstDigits}${'xxxxxx'}${lastDigits}`,
      card_holder_name: holderName,
      card_exp_month: expirationMonth,
      card_exp_year: expirationYear,
      card_brand: cardBrand,
      credit_card_tokens: {
        rede_token: token,
      },
    };
  };
  return { createPayload };
};

const redeStrategy = RedeStrategy();

export default redeStrategy;
