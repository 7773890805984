import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import LocalStorageManager from 'utils/localStorageManager';
import { createValidator, required } from '../../../utils/formValidations';
import TransferForm from './TransferForm';

const validate = createValidator({
  personType: required,
  bank: required,
});

const mapStateToProps = (state) => {
  const { purchase } = state;
  const purchaseJs = purchase.toJS();
  const { documentType, selectedPaymentMethod } = purchaseJs;
  const { engine, provider } = selectedPaymentMethod;
  const isPSEMercadoPago = engine === 'mercadopago' && provider === 'pse';
  const storedTransferForm = LocalStorageManager.get('form.transfer');
  const {
    personType: storedPersonType,
    bank: storedBank,
    addressAttributes: storedAddressAttributes,
  } = storedTransferForm || {};

  return {
    initialValues: {
      // Set the personType field for legal entities
      // if the documentType is NIT to avoid submit error
      personType: documentType === 'NIT' ? '1' : storedPersonType,
      bank: storedBank,
      addressAttributes: storedAddressAttributes || {},
    },
    showAddressFields: isPSEMercadoPago,
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'transfer',
    destroyOnUnmount: false,
    enableReinitialize: true,
    validate,
    onSubmitSuccess: (result, dispatch, props) => {
      LocalStorageManager.set('form.transfer', props.values);
    },
  }),
)(TransferForm);
