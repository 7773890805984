import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, BoxCollapse, Spacing, Text } from '@reservamos/elements';
import useExperimentalFeature from 'hooks/useExperimentalFeature';
import PassengerInfoHeader from './PassengerInfoHeader';

/**
 * Renders the PassengerBoxCollapse component, which displays passenger information
 * inside a collapsible box. The component shows a header with passenger details
 * and a toggle to expand or collapse the content.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.name] - The name of the passenger. If not provided, `labelPassenger` will be used as a fallback.
 * @param {string} props.typePassenger - The type of passenger, which determines the displayed icon and category.
 *    It can be one of the following values: "minor", "older", "general", "student", "teacher", "wheelchair_handicap", "special", "pet_friendly".
 * @param {number} props.numberPassenger - The unique identifier or label for the passenger number (e.g., "1", "2", "A", "B").
 * @param {number} props.numberSeat - The seat number assigned to the passenger.
 * @param {boolean} [props.returnSeat=false] - Indicates if the trip is a round trip.
 *    If `true`, an additional seat is displayed to reflect the round trip.
 * @param {JSX.Element} props.children - The content to be displayed inside the collapsible box.
 *
 * @returns {JSX.Element} The rendered PassengerBoxCollapse component.
 */
const PassengerBoxCollapse = ({
  children,
  name,
  typePassenger,
  returnSeat,
  numberSeat,
  numberPassenger,
}) => {
  const showFrequentPassengers = useExperimentalFeature('SHOW_FREQUENT_PASSENGERS');
  const [isExpanded, setIsExpanded] = useState(true);
  const { t } = useTranslation('passengers');

  const handleToggle = (isExpanded) => setIsExpanded(isExpanded);

  if (!showFrequentPassengers) {
    return <>{children}</>;
  }

  return (
    <Box
      paddingHorizontal="XS"
      paddingVertical="XS"
      borderColor="grayBorder"
      border="all"
      borderRadius="M"
    >
      <div className="relative">
        <BoxCollapse
          alphaBg="5"
          bgHeader="primary"
          borderColor=""
          borderRadius="M"
          borderRadiusHeader="M"
          mobileAlignItems="end"
          defaultExpanded
          onExpandedChange={handleToggle}
          titleContent={
            <Spacing
              alignItems="center"
              fullWidth
              justifyContent="space-between"
              mobileAlign="flex-end"
              size="XS"
            >
              <PassengerInfoHeader
                name={name}
                typePassenger={typePassenger}
                returnSeat={returnSeat}
                numberSeat={numberSeat}
                numberPassenger={numberPassenger}
              />

              <Text color="grayMedium">{isExpanded ? t('hide') : t('edit')}</Text>

              <div className="absolute top-[5px] right-[5px] lg:top-[10px] lg:right-[105px] opacity-20">
                <Text color="primary" size="XXL" italic>
                  {`P${numberPassenger}`}
                </Text>
              </div>
            </Spacing>
          }
        >
          <Box bgColor="" borderRadius="M" paddingHorizontal="S" paddingVertical="S">
            {children}
          </Box>
        </BoxCollapse>
      </div>
    </Box>
  );
};

PassengerBoxCollapse.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
  typePassenger: PropTypes.oneOf([
    'minor',
    'older',
    'general',
    'student',
    'teacher',
    'wheelchair_handicap',
    'special',
    'pet_friendly',
  ]),
  numberPassenger: PropTypes.number,
  numberSeat: PropTypes.number,
  returnSeat: PropTypes.number,
};

PassengerBoxCollapse.defaultProps = {
  name: '',
  typePassenger: 'general',
  numberSeat: 11,
  numberPassenger: 1,
  returnSeat: null,
};

export default PassengerBoxCollapse;
