import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import filtersTypes from '../constants/filtersTypes';
import useTrips from './store/useTrips';

const { STOPS } = filtersTypes;

/**
 * Custom hook to get the available stops.
 * @param {String} way - Trip way.
 * @returns Array with the available stops.
 */
const useAvailableStops = (way, isNewDesign) => {
  const { t } = useTranslation();

  const search = useSelector((state) => state.search);
  const { trips } = useTrips();

  const searchId = search.getIn([way, 'id']);
  const tripsList = trips[searchId]?.buses?.trips;
  const tripListFiltered = tripsList?.filter((t) => !t.openTicket) || [];

  if (!tripsList || !tripListFiltered.length) return [];

  const stopsAvailable = [];
  if (tripListFiltered.find((trip) => trip.stops === 0))
    stopsAvailable.push(
      isNewDesign
        ? {
            i18Key: 'search:trip_stopovers_direct',
            label: t('search:trip_stopovers_direct'),
            value: 'direct',
            filterType: STOPS,
          }
        : 'direct',
    );
  if (tripListFiltered.find((trip) => trip.stops > 0))
    stopsAvailable.push(
      isNewDesign
        ? {
            i18Key: 'search:label.selector_stopover_trip',
            label: t('search:label.selector_stopover_trip'),
            value: 'multiple',
            filterType: STOPS,
          }
        : 'multiple',
    );
  return stopsAvailable;
};

export default useAvailableStops;
