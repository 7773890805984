import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import 'styles/components/TripsFilter';
import { Box, Icon, NewSeat, Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import adultIcon from '../images/brands/gfa/avatars/adult.png';
import minorIcon from '../images/brands/gfa/avatars/minor.png';
import studentIcon from '../images/brands/gfa/avatars/student.png';
import teacherIcon from '../images/brands/gfa/avatars/teacher.png';
import insenIcon from '../images/brands/gfa/avatars/old-adult.png';
import adultIconVariant from '../images/brands/etn/avatars/adult.png';
import minorIconVariant from '../images/brands/etn/avatars/minor.png';
import studentIconVariant from '../images/brands/etn/avatars/student.png';
import teacherIconVariant from '../images/brands/etn/avatars/teacher.png';
import insenIconVariant from '../images/brands/etn/avatars/old-adult.png';

const types = {
  minor: {
    icon: minorIcon,
    iconVariant: minorIconVariant,
    label: 'category_minor',
  },
  older: {
    icon: insenIcon,
    iconVariant: insenIconVariant,
    label: 'category_older',
  },
  general: {
    icon: adultIcon,
    iconVariant: adultIconVariant,
    label: 'category_general',
  },
  student: {
    icon: studentIcon,
    iconVariant: studentIconVariant,
    label: 'category_student',
  },
  teacher: {
    icon: teacherIcon,
    iconVariant: teacherIconVariant,
    label: 'category_teacher',
  },
  wheelchair_handicap: {
    icon: null,
    iconVariant: null,
    label: 'category_wheelchair_handicap',
  },
  special: {
    icon: null,
    iconVariant: null,
    label: 'category_special',
  },
  pet_friendly: {
    icon: null,
    iconVariant: null,
    label: 'category_pet_friendly',
  },
};

/**
 * Renders the PassengerInfoHeader component, which displays passenger information,
 * including the passenger's name, type, category icon, and seat selection details.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.name] - The name of the passenger. If not provided, it defaults to "Passenger X" where X is the passenger number.
 * @param {string} [props.typePassenger='general'] - The type of passenger, which determines the displayed icon and category.
 *    It can be one of the following values:
 *    - "minor" "older" "general" "student""teacher" "wheelchair_handicap" "special""pet_friendly"
 * @param {number} [props.numberSeat=11] - The assigned seat number for the passenger. If not provided, it defaults to 11.
 * @param {number} [props.numberPassenger=1] - The passenger number, used to display "Passenger X" when `name` is not provided.
 *    This is useful when displaying multiple passengers in a list.
 * @param {number} [props.returnSeat=null] - If the passenger has a round trip, this prop will display the seat number for the return trip.
 *    If `null`, no return seat will be displayed.
 *
 * @returns {JSX.Element} The rendered PassengerInfoHeader component, which shows the passenger name, type, icon, and seat(s) information.
 */
const PassengerInfoHeader = ({ name, typePassenger, returnSeat, numberSeat, numberPassenger }) => {
  const { t } = useTranslation('general');
  const { icon, iconVariant, label } = types[typePassenger];

  const { features } = useSelector((state) => state.whitelabelConfig);
  const isFlat = features.FUNNEL_STYLE === 'FLAT';

  return (
    <Spacing alignItems="center" isResponsive mobileAlign="flex-start" responsiveSize="S" size="XL">
      <Text mobileSize="S" weight="semibold">
        {name || `${t('passengers:label.passenger')} ${numberPassenger}`}
      </Text>

      <Spacing responsiveSize="M" size="L" alignItems="center">
        <Spacing size="XS" alignItems="center">
          <Box
            bgColor="primary"
            alphaBg="XS"
            borderRadius="L"
            paddingHorizontal="XS"
            paddingVertical="XS"
          >
            <Icon type={isFlat ? iconVariant : icon} size="S" />
          </Box>

          <Text mobileSize="S" color="grayMedium">
            {t(label)}
          </Text>
        </Spacing>

        {numberSeat ? (
          <Spacing size="XS" alignItems="center">
            <Text mobileSize="S" color="grayMedium">
              {t('labelSeats')}
            </Text>

            <NewSeat isSmall number={numberSeat} type="selected" />
            {returnSeat && <NewSeat isSmall number={returnSeat} type="selected" />}
          </Spacing>
        ) : (
          <Text mobileSize="S" color="grayMedium">
            {t('text.no_assigned_seat')}
          </Text>
        )}
      </Spacing>
    </Spacing>
  );
};

PassengerInfoHeader.propTypes = {
  name: PropTypes.string,
  typePassenger: PropTypes.oneOf([
    'minor',
    'older',
    'general',
    'student',
    'teacher',
    'wheelchair_handicap',
    'special',
    'pet_friendly',
  ]),
  numberSeat: PropTypes.number,
  numberPassenger: PropTypes.number,
  returnSeat: PropTypes.number,
};

PassengerInfoHeader.defaultProps = {
  name: '',
  typePassenger: 'general',
  numberSeat: 11,
  numberPassenger: 1,
  returnSeat: null,
};

export default PassengerInfoHeader;
