import React from 'react';
import PropTypes from 'prop-types';
import paymethodcheck from 'images/payment/accepted.svg';
import 'styles/components/purchase/InstallmentsSelectedPlan';
import { useTranslation } from 'react-i18next';
import { getCurrencySuffix } from 'utils/currency';
import { Button, Currency, Icon, Spacing, Text } from '@reservamos/elements';

const propTypes = {
  selectedInstallmentsPlan: PropTypes.shape({
    months: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    monthlyPayment: PropTypes.number.isRequired,
    interestByMonth: PropTypes.number.isRequired,
  }).isRequired,
  onChangeClick: PropTypes.func.isRequired,
};

const InstallmentsSelectedPlan = ({ selectedInstallmentsPlan, onChangeClick }) => {
  const { t } = useTranslation('purchase');
  const { months, total, monthlyPayment, interestByMonth } = selectedInstallmentsPlan;
  const selectedPlanLabel = interestByMonth > 0 ? 'text.payments' : 'text.payments_no_interest';

  return (
    <Spacing vertical>
      <Spacing fullWidth justifyContent="space-between" alignItems="center">
        <Text weight="semibold">
          {t(selectedPlanLabel, {
            months,
            monthlyPayment,
            currency_suffix: getCurrencySuffix(),
          })}
        </Text>
        <Button isRounded onClick={onChangeClick} padding="S">
          {t('link.change_number_of_terms')}
        </Button>
      </Spacing>

      <div className="installments-selected-plan">
        <Spacing fullWidth alignItems="center" size="S">
          <Icon size="S" type={paymethodcheck} />
          <Spacing justifyContent="space-between" alignItems="flex-end" fullWidth>
            <div>
              <Text mobileSize="S" weight="bold">
                {`${months} ${t('payment:payments_of')} `}
              </Text>
              <Currency
                weight="regular"
                price={monthlyPayment}
                currency={getCurrencySuffix()}
                mobileSize="S"
              />
            </div>
            <Text mobileSize="S">
              Total {total} {getCurrencySuffix()}
            </Text>
          </Spacing>
        </Spacing>
      </div>
    </Spacing>
  );
};

InstallmentsSelectedPlan.propTypes = propTypes;

export default InstallmentsSelectedPlan;
