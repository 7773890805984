/**
 * RedePixStrategy function.
 * @returns {Object} An object containing needsRedirect and createToken properties.
 */
const RedePixStrategy = () => {
  const needsRedirect = false;

  /**
   * Creates a token.
   * @returns {Promise<string>} A promise that resolves to an empty string.
   */
  const createToken = async () => {
    return '';
  };

  return {
    needsRedirect,
    createToken,
  };
};

const redePixStrategy = RedePixStrategy();

export default redePixStrategy;
