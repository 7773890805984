import React from 'react';
import { trackEvent } from 'user-analytics';
import whatsappLogo from 'images/whatsapp/whatsapp-inline-white.svg';
import PropTypes from 'prop-types';
import useWhitelabelCopies from 'hooks/whitelabel/useWhitelabelCopies';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';

/**
 * WhatsappButton component
 *
 * This component renders a WhatsApp button with a logo and a link to the WhatsApp contact.
 *
 * @param {Object} props - The component props
 * @param {string} props.token - The token of the purchase
 * @returns {React.ReactElement} The WhatsApp button component
 */
function WhatsappButton({ token }) {
  const { provider } = useWhitelabelCopies();
  const { WHATSAPP_FAILED_PAYMENT_BUTTON } = useWhitelabelFeatures();
  const { whatsapp } = provider;
  if (!whatsapp.length || !whatsapp[0].value || !WHATSAPP_FAILED_PAYMENT_BUTTON) return null;
  const { countryCode = '52', value } = whatsapp[0];

  const whatsappUrl = `https://wa.me/send/?app_absent=0&phone=${countryCode}${value}&text=Ayuda%20con%20el%20pago%20de%20mi%20compra%20${token}`;

  return (
    <a
      className="bg-whatsapp-primary h-[50px] md:min-w-[174px] w-full md:max-w-fit rounded p-2 transition-all duration-200 ease-in-out border border-whatsapp-accent hover:bg-whatsapp-accent"
      href={whatsappUrl}
      target="_blank"
      tabIndex="0"
      onClick={() =>
        trackEvent('Failed Payment WhatsApp Support Clicked', {
          Token: token,
        })
      }
    >
      <img className="w-full h-full object-contain" src={whatsappLogo} alt="Whatsapp" />
    </a>
  );
}

WhatsappButton.propTypes = {
  token: PropTypes.string.isRequired,
};

export default WhatsappButton;
