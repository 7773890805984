import React from 'react';
import PropTypes from 'prop-types';
import { Box, Currency, Gap, Icon, Spacing, Text } from '@reservamos/elements';
import './ReturnButton.scss';
import { useTranslation } from 'react-i18next';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';

/**
 * Return reminder to add the return simple ticket for the current trip.
 * @param props - Component props.
 * @param {Function} props.onClick - A function to be called when the button is clicked.
 * @param {number} props.price - The price of the return trip.
 * @param {string} props.origin - The origin location of the return trip.
 * @param {string} props.destination - The destination location of the return trip.
 * @returns {JSX.Element}
 */
const ReturnButton = ({ onClick, price, origin, destination }) => {
  const features = useWhitelabelFeatures();
  const { t } = useTranslation('trips');

  const isFlat = features.FUNNEL_STYLE === 'FLAT';

  return (
    <button onClick={onClick} className={isFlat ? 'return-button-rounded' : 'return-button'}>
      <Box
        borderRadius={isFlat ? 'L' : 'M'}
        bgColor="primary"
        alphaBg="XS"
        paddingHorizontal="XS"
        paddingVertical="XS"
      >
        <Spacing alignItems="center" size="S" fullWidth>
          <Box
            borderRadius={isFlat ? 'L' : 'M'}
            bgColor="white"
            paddingHorizontal="M"
            paddingVertical="S"
            hasWidth
          >
            <Spacing size="XS" alignItems="center">
              <Spacing size="XS" fullWidth vertical>
                <Text color="grayStrong" size="S" weight="semibold">
                  {t('trips:label.select_schedule')}
                </Text>

                <Gap alignItems="center">
                  <Icon type="Arrows" size="S" color="grayStrong" />

                  <Gap alignItems="center">
                    <Text
                      mobileSize="XXS"
                      size="XS"
                      color="grayStrong"
                      maxWidthEllipsis={75}
                      mobileMaxWidthEllipsis={60}
                    >
                      {origin}
                    </Text>
                    <Text color="primary"> - </Text>
                    <Text
                      mobileSize="XXS"
                      size="XS"
                      color="grayStrong"
                      maxWidthEllipsis={75}
                      mobileMaxWidthEllipsis={60}
                    >
                      {destination}
                    </Text>
                  </Gap>
                </Gap>
              </Spacing>

              <div className="return-button-price">
                <Text color="primary" size="XXS">
                  Desde
                </Text>
                <Currency
                  currency=""
                  price={price}
                  size="S"
                  color="primary"
                  decimals="0"
                  currencyLowercase
                  weight="bold"
                />
              </div>
            </Spacing>
          </Box>

          <Icon type="Plus" variant="primary" size="S" />
          <Spacing size="S" />
        </Spacing>
      </Box>
    </button>
  );
};

ReturnButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  price: PropTypes.number,
  origin: PropTypes.string,
  destination: PropTypes.string,
};

ReturnButton.defaultProps = {
  price: '866',
  origin: 'Tepic',
  destination: 'Ciudad de México',
};

export default ReturnButton;
