import React from 'react';
import { Text, Spacing, Icon } from '@reservamos/elements';
import PropTypes from 'prop-types';
import './InstallmentsButton.scss';
import { useTranslation } from 'react-i18next';

/**
 * Renders the InstallmentsButton component.
 *
 * @param {Object} props - The component props.
 * @param {func} props.onClick - Onclick func
 * @returns {JSX.Element} The rendered component.
 */
const InstallmentsButton = ({ onClick, actionLabel }) => {
  const { t } = useTranslation('common');
  return (
    <div className="installments-button" onClick={onClick}>
      <Spacing size="S" alignItems="center">
        <Icon type="Success" color="success" size="S" />
        <Text weight="semibold" mobileSize="S">
          {t('payment:single_payment')}
        </Text>
      </Spacing>
      <div className="installments-button-badge">
        <Spacing size="S" alignItems="center">
          <Text weight="semibold" mobileSize="S">
            {actionLabel}
          </Text>
          <Icon type="ChevronRight" color="grayLight" size="S" />
        </Spacing>
      </div>
    </div>
  );
};

InstallmentsButton.propTypes = {
  onClick: PropTypes.func,
  actionLabel: PropTypes.string,
};

export default InstallmentsButton;
