import { useContext } from 'react';
import FrequentPassengersContext from './FrequentPassengersContext';

const useFrequentPassengersContext = () => {
  const contextValue = useContext(FrequentPassengersContext);

  if (!contextValue) {
    throw new Error(
      'FrequentPassengersContext value is undefined, you likely forgot to use FrequentPassengersProvider',
    );
  }

  return contextValue;
};

export default useFrequentPassengersContext;
