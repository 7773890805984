import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Text } from '@reservamos/elements';
import InstallmentsSelectedPlan from 'components/purchase/InstallmentsSelectedPlan';
import 'styles/components/purchase/CardFormPaymentOptions';
import mastercard from 'images/payment/mastercard.svg';
import visa from 'images/payment/visa.svg';
import amex from 'images/payment/americanexpress.svg';
import InstallmentsButton from 'ui/atoms/InstallmentsButton';
import { useTranslation } from 'react-i18next';
import PaymentImage from '../../ui/atoms/PaymentImage';

const propTypes = {
  qualifiesForMonthlyInstallments: PropTypes.bool.isRequired,
  monthlySelectedPlan: PropTypes.number.isRequired,
  selectedInstallmentsPlan: PropTypes.shape({
    card: PropTypes.string.isRequired,
    months: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    monthlyPayment: PropTypes.number.isRequired,
  }).isRequired,
  maxInstallmentsPlan: PropTypes.shape({
    months: PropTypes.number,
    monthlyPayment: PropTypes.number,
    interestByMonth: PropTypes.number,
  }).isRequired,
  onChangeClick: PropTypes.func.isRequired,
};

function CardFormPaymentOptions({
  qualifiesForMonthlyInstallments,
  monthlySelectedPlan,
  selectedInstallmentsPlan,
  maxInstallmentsPlan,
  onChangeClick,
}) {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');
  const { months, interestByMonth } = maxInstallmentsPlan;
  const isCol = env.brand === 'ochoa' || env.brand === 'brasilia';
  const showDebitOptions =
    !isCol && Boolean(env.openPay?.enabled) && features.SHOW_CREDIT_DEBIT_LOGOS;
  const showCreditOptions = !isCol && features.SHOW_CREDIT_DEBIT_LOGOS;
  const showLogosContainer = isCol || showCreditOptions || showDebitOptions;
  const creditOptions = env.lang.default.startsWith('pt') ? 'credit-options-pt' : 'credit-options';
  const monthlyPaymentLabel =
    interestByMonth > 0 ? 'payment:pay_up_to' : 'payment:pay_up_to_without_interest';

  if (!features.INSTALLMENTS_ENABLED || !qualifiesForMonthlyInstallments) {
    return showLogosContainer ? (
      <div className="checkout-section">
        {features.SHOW_CREDIT_DEBIT_LOGOS && <Text size="S">{t('purchase:accepted_cards')}</Text>}
        {isCol && <PaymentImage type="credit-openpay-col" />}
        {showCreditOptions && <PaymentImage type={creditOptions} />}
        {showDebitOptions && <PaymentImage type="debit-options" />}
      </div>
    ) : null;
  }

  if (monthlySelectedPlan > 1) {
    return (
      <div className="checkout-section">
        {features.SHOW_CREDIT_DEBIT_LOGOS && <Text size="S">{t('purchase:accepted_cards')}</Text>}
        <InstallmentsSelectedPlan
          selectedInstallmentsPlan={selectedInstallmentsPlan}
          onChangeClick={onChangeClick}
        />
      </div>
    );
  }

  return (
    <div className="checkout-section">
      {features.SHOW_CREDIT_DEBIT_LOGOS && (
        <>
          <Text size="S">{t('accepted_cards')}</Text>
          {showCreditOptions && <PaymentImage type={creditOptions} />}
          {!features.SHOW_ADYEN_CARD_LOGOS && (
            <div className="cards-logos">
              <img src={visa} />
              <img src={mastercard} />
              <img src={amex} />
            </div>
          )}
        </>
      )}

      <div className="mb-3">
        <InstallmentsButton
          onClick={onChangeClick}
          actionLabel={t(monthlyPaymentLabel, {
            months,
          })}
        />
      </div>

      {features.SHOW_INSTALLMENTS_BANKS && (
        <div className="installments-payment-note">
          <h6 className="checkout-text">* {t('label.participating_banks')}:</h6>
          <p className="checkout-disclaimer-text">
            BBVA Bancomer, Banorte, HSBC, Santander, Scotiabank, Inbursa, Invex, Monex, Afirme,
            BanRegio.
          </p>
        </div>
      )}
    </div>
  );
}

CardFormPaymentOptions.propTypes = propTypes;

export default CardFormPaymentOptions;
