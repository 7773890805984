import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CardFormPaymentOptions from '../CardFormPaymentOptions';
import InstallmentsPlanSelectorOv from '../InstallmentsPlanSelectorOv';

/**
 * Renders the Installments component.
 * @param {*} param0 - Params
 * @param {boolean} param0.qualifiesForMonthlyInstallments - Qualifies for monthly installments
 * @param {number} param0.monthlySelectedPlan - Monthly selected plan number. e.g. 1, 3, 6, 12
 * @param {Object} param0.selectedInstallmentsPlan - Selected installments plan
 * @param {Object} param0.maxInstallmentsPlan - Max installments plan
 * @param {Object} param0.paymentPlans - Valid payment plans by card
 * @param {func} param0.resetInstallmentsPlan - Function that dispatches actions to reset installments plan
 * @returns {JSX.Element} The rendered component
 */
const Installments = ({
  qualifiesForMonthlyInstallments,
  monthlySelectedPlan,
  selectedInstallmentsPlan,
  maxInstallmentsPlan,
  paymentPlans,
  resetInstallmentsPlan,
}) => {
  const [showModal, setShowModal] = useState(false);

  /**
   * Handle toggle modal for selecting installments plan
   */
  const handleToggleModal = () => {
    setShowModal((prev) => !prev);
  };
  return (
    <>
      <CardFormPaymentOptions
        qualifiesForMonthlyInstallments={qualifiesForMonthlyInstallments}
        monthlySelectedPlan={monthlySelectedPlan}
        selectedInstallmentsPlan={selectedInstallmentsPlan}
        maxInstallmentsPlan={maxInstallmentsPlan}
        onChangeClick={handleToggleModal}
      />

      <InstallmentsPlanSelectorOv
        visible={showModal}
        paymentPlans={paymentPlans}
        handleToggleModal={handleToggleModal}
        resetInstallmentsPlan={resetInstallmentsPlan}
      />
    </>
  );
};

Installments.propTypes = {
  qualifiesForMonthlyInstallments: PropTypes.bool.isRequired,
  monthlySelectedPlan: PropTypes.number.isRequired,
  selectedInstallmentsPlan: PropTypes.shape({
    card: PropTypes.string.isRequired,
    months: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    monthlyPayment: PropTypes.number.isRequired,
  }).isRequired,
  maxInstallmentsPlan: PropTypes.shape({
    months: PropTypes.number,
    monthlyPayment: PropTypes.number,
  }).isRequired,
  paymentPlans: PropTypes.object.isRequired,
  resetInstallmentsPlan: PropTypes.func.isRequired,
};

export default Installments;
