import adultIcon from '../images/brands/gfa/avatars/adult.png';
import minorIcon from '../images/brands/gfa/avatars/minor.png';
import studentIcon from '../images/brands/gfa/avatars/student.png';
import teacherIcon from '../images/brands/gfa/avatars/teacher.png';
import insenIcon from '../images/brands/gfa/avatars/old-adult.png';
import pcdIcon from '../images/brands/gfa/avatars/pcd.png';
import adultIconVariant from '../images/brands/etn/avatars/adult.png';
import minorIconVariant from '../images/brands/etn/avatars/minor.png';
import studentIconVariant from '../images/brands/etn/avatars/student.png';
import teacherIconVariant from '../images/brands/etn/avatars/teacher.png';
import insenIconVariant from '../images/brands/etn/avatars/old-adult.png';
import pcdIconVariant from '../images/brands/etn/avatars/pcd.png';

const passengerTypes = {
  minor: {
    icon: minorIcon,
    iconVariant: minorIconVariant,
    label: 'type.minor_simple',
    disclaimer: 'selector.disclaimer_minor',
  },
  older: {
    icon: insenIcon,
    iconVariant: insenIconVariant,
    label: 'type.older',
    disclaimer: 'selector.disclaimer_older',
  },
  general: {
    icon: adultIcon,
    iconVariant: adultIconVariant,
    label: 'type.general',
  },
  adult: {
    icon: adultIcon,
    iconVariant: adultIconVariant,
    label: 'type.general',
  },
  student: {
    icon: studentIcon,
    iconVariant: studentIconVariant,
    label: 'type.student',
    disclaimer: 'selector.disclaimer_student',
  },
  teacher: {
    icon: teacherIcon,
    iconVariant: teacherIconVariant,
    label: 'type.teacher',
    disclaimer: 'selector.disclaimer_teacher',
  },
  pcd: {
    icon: pcdIcon || (!pcdIcon && null),
    iconVariant: pcdIconVariant || (!pcdIconVariant && null),
    label: 'type.special',
    disclaimer: 'selector.disclaimer_wheelchair_handicap',
  },
  wheelchair_handicap: {
    icon: null,
    iconVariant: null,
    label: 'category_wheelchair_handicap_seats',
    disclaimer: 'selector.disclaimer_wheelchair_handicap_seats',
  },
  pet_friendly: {
    icon: null,
    iconVariant: null,
    label: 'type.pet',
    disclaimer: 'selector.disclaimer_pet_friendly',
  },
};

export default passengerTypes;
