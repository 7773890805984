import { connect } from 'react-redux';
import { resetInstallmentsPlan, selectInstalmentsPlan } from '@/actions/purchase';
import Installments from './Installments';

const mapStateToProps = ({ purchase }) => {
  const purchaseJs = purchase.toJS();

  const {
    qualifiesForMonthlyInstallments,
    paymentPlans,
    monthlySelectedPlan,
    selectedInstallmentsPlan,
  } = purchaseJs;

  let visaMcPlan = {};
  let maxMonths = 1;
  let maxInstallmentsPlan = {};

  if (qualifiesForMonthlyInstallments) {
    visaMcPlan = paymentPlans.visaMc;
    maxMonths = Number(Math.max(...Object.keys(visaMcPlan)));
    maxInstallmentsPlan = { ...visaMcPlan[maxMonths], months: maxMonths };
  }

  return {
    qualifiesForMonthlyInstallments,
    paymentPlans,
    maxInstallmentsPlan,
    monthlySelectedPlan,
    selectedInstallmentsPlan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetInstallmentsPlan: () => {
      dispatch(resetInstallmentsPlan());
      dispatch(selectInstalmentsPlan('singlePayment'));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Installments);
