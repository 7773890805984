import { connect } from 'react-redux';
import { List } from 'immutable';
import { toggleModal } from '@/actions';
import {
  setProviderSortOrder,
  setProviderFilterBy,
  resetProviderFilters,
} from '@/actions/providers';
import { setTripFilter } from '@/actions/tripFilters';
import parseQueryString from 'utils/queryString/parseQueryString';
import getTransportState from 'utils/search/getTransportState';
import mergeProviders from 'utils/providers/mergeProviders';
import stringifyQueryString from 'utils/queryString/stringifyQueryString';
import Providers from './Providers';

const filterByTransport = (providers, showing) => {
  if (!providers) return List();
  if (showing === 'all') return providers;

  return providers.filter((provider) => showing === provider.get('transportType'));
};

const filterByOpenProvider = (providers, trips) => {
  if (!providers) return List();

  // The provider must have at least one open trip
  return providers.filter((provider) => {
    // Get all trips for the provider
    const providerTrips = trips.filter((trip) => trip.get('providerId') === provider.get('id'));
    if (!providerTrips) return false;

    // Check if at least one trip is open
    return providerTrips.some((trip) => trip.get('openTicket'));
  });
};

const retrieveOpenTrip = (trips) => {
  if (!trips || trips.size === 0) return null;
  const openTrips = trips
    .filter((t) => t.get('openTicket'))
    .sort((a, b) => a.getIn(['pricing', 'total']) - b.getIn(['pricing', 'total']));

  return openTrips.size > 0 ? openTrips.first().toJS() : null;
};

const mapStateToProps = (state, ownProps) => {
  const {
    config,
    search,
    providers,
    cities,
    terminals,
    overlayView,
    trips,
    whitelabelConfig: { features },
  } = state;
  const { match, location } = ownProps;
  const places = { ...cities, ...terminals };
  const { params, url: currentUrl } = match;
  const { departureDate, returnDate, passengers = 'A1', departureId, way = 'departure' } = params;
  const { adAttribution, returnIsOpenTicket } = parseQueryString(location.search);
  const dSearch = search.get('departure') || List([]);
  const rSearch = search.get('return') || List([]);
  const dTransportState = providers.getIn([dSearch.get('id'), 'state']);
  const rTransportState = providers.getIn([rSearch.get('id'), 'state']);
  const transportState = getTransportState(dTransportState, rTransportState, !!returnDate, way);
  const dProviders = providers.getIn([dSearch.get('id'), 'list']);
  const rProviders = providers.getIn([rSearch.get('id'), 'list']);
  const showing = providers.get('showing');
  const mergedProviders = mergeProviders(dProviders, rProviders, way, !!returnDate);
  const uniqueLine = mergedProviders.size === 1 ? mergedProviders.getIn([0, 'id']) : '';

  let openTrip;
  if (features.SHOW_OPEN_TICKET_ON_PROVIDERS && !returnIsOpenTicket) {
    const searchId = way === 'departure' ? dSearch.get('id') : rSearch.get('id');
    const tripList = trips.getIn([searchId, 'buses', 'trips']);
    openTrip = retrieveOpenTrip(tripList);
  }

  let filteredProviders = filterByTransport(mergedProviders, showing);
  if (features.SHOW_OPEN_TICKET_ON_PROVIDERS && returnIsOpenTicket && way === 'return') {
    const searchId = way === 'departure' ? dSearch.get('id') : rSearch.get('id');
    const tripList = trips.getIn([searchId, 'buses', 'trips']);
    filteredProviders = filterByOpenProvider(filteredProviders, tripList);
  }

  return {
    currentPath: location.pathname,
    currentUrl,
    passengers,
    way,
    departureId,
    roundTrip: !!returnDate,
    departureDate,
    returnDate,
    adAttribution,
    origin: places[dSearch.get('originId')],
    destination: places[dSearch.get('destinationId')],
    installmentsMinAmount: config.installmentsMinAmount,
    providers: filteredProviders.toJS(),
    noResults: transportState.valueSeq().every((tState) => tState === 'disabled'),
    isLoading: transportState.valueSeq().some((tState) => tState === 'pending'),
    sortBy: providers.get('sortBy'),
    filterBy: providers.get('filterBy'),
    providerSortVisible: overlayView.get('providerSortVisible'),
    uniqueLine,
    isOpenTicket: returnIsOpenTicket,
    openTrip,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  showModal: (component, active) => dispatch(toggleModal('visible', component, { active })),

  sortProvidersBy: (sortBy) => dispatch(setProviderSortOrder(sortBy)),

  filterProvidersBy: (filterBy) => dispatch(setProviderFilterBy(filterBy)),

  setTripFilter: (filterBy, active) => dispatch(setTripFilter(filterBy, active)),

  resetFilters: () => dispatch(resetProviderFilters()),

  transitionTo: (route, searchQuery) => {
    const queryString = stringifyQueryString(searchQuery, ownProps.location.search);

    ownProps.history.push(`${route}${queryString}`);
  },

  next: (route, searchQuery) => {
    const queryString = stringifyQueryString(searchQuery, ownProps.location.search);

    ownProps.history.push(`${route}${queryString}`);
  },

  skip: (route, searchQuery) => {
    const queryString = stringifyQueryString(searchQuery, ownProps.location.search);
    return ownProps.history.replace(`${route}${queryString}`);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Providers);
