import useGrowthBookFeatureValue from 'components/GrowthBookProvider/useGrowthBookFeatureValue';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';

/**
 * Custom hook to get the value of an experimental feature.
 * It will return the value of the feature from GrowthBook if it is defined,
 * otherwise it will return the value of the feature from the whitelabel config.
 *
 * Having the feature defined at GrowthBook is a key part to test and rollout new features,
 * because at the experiment phase the feature value can change rapidly.
 *
 * @param {string} featureName - The name of the feature
 * @returns {boolean | string | object | null} - The value of the feature
 */
const useExperimentalFeature = (featureName) => {
  const featureValue = useGrowthBookFeatureValue(featureName.toLowerCase());
  const features = useWhitelabelFeatures();

  return featureValue ?? features[featureName];
};

export default useExperimentalFeature;
