import * as types from 'constants/ActionTypes';
import { camelizeKeys } from 'humps';
import { Map } from 'immutable';

// Load persisted state from localStorage
const loadPersistedState = () => {
  try {
    const savedState = localStorage.getItem('userPreferences');
    return savedState ? Map(JSON.parse(savedState)) : null;
  } catch (err) {

    return null;
  }
};

const defaultState =
  loadPersistedState() ||
  Map({
    recommendedTrips: {
      isLoading: false,
      requested: false,
    },
    frequentPassengers: {
      passengers: [],
      isLoading: false,
      requested: false,
    },
  });

/**
 * Reducer for user preferences.
 *
 * @param {Object} state - The current state.
 * @param {Object} action - The action object.
 * @returns {Object} The updated state.
 */
export default function userPreferences(state = defaultState, action) {
  let newState;

  switch (action.type) {
    case types.SET_RECOMMENDED_TRIPS: {
      newState = state.set('recommendedTrips', {
        ...state.get('recommendedTrips'),
        [action.searchId]: { ...camelizeKeys(action.trips) },
        isLoading: false,
        requested: true,
      });
      break;
    }
    case types.SET_RECOMMENDED_TRIPS_LOADING:
      newState = state.set('recommendedTrips', {
        ...state.get('recommendedTrips'),
        isLoading: action.isLoading,
        requested: !action.isLoading,
      });
      break;
    case types.SET_FREQUENT_PASSENGERS:
      newState = state.set('frequentPassengers', {
        passengers: action.passengers,
        isLoading: false,
        requested: true,
      });
      break;
    case types.SET_FREQUENT_PASSENGERS_LOADING:
      newState = state.set('frequentPassengers', {
        ...state.get('frequentPassengers'),
        isLoading: action.isLoading,
        requested: !action.isLoading,
      });
      break;
    default:
      return state;
  }

  // Persist the new state to localStorage
  try {
    localStorage.setItem('userPreferences', JSON.stringify(newState.toJS()));
  } catch (err) {

  }

  return newState;
}
