import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Modal, Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import InstallmentsRadio from 'components/purchase/InstallmentsRadio';
import CSSVariablesProvider from 'components/CSSVariablesProvider/CSSVariablesProvider';

const propTypes = {
  visible: PropTypes.bool.isRequired,
  paymentPlans: PropTypes.shape({
    visaMc: PropTypes.arrayOf(
      PropTypes.shape({
        total: PropTypes.number.isRequired,
        monthlyPayment: PropTypes.number.isRequired,
      }),
    ),
    amex: PropTypes.arrayOf(
      PropTypes.shape({
        total: PropTypes.number.isRequired,
        monthlyPayment: PropTypes.number.isRequired,
      }),
    ),
  }),
  selectedCard: PropTypes.string.isRequired,
  onSelectPlan: PropTypes.func.isRequired,
  onSelectCard: PropTypes.func.isRequired,
  handleToggleModal: PropTypes.func.isRequired,
  resetInstallmentsPlan: PropTypes.func.isRequired,
  selectedInstallmentsPlan: PropTypes.shape({
    card: PropTypes.string.isRequired,
    months: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    monthlyPayment: PropTypes.number.isRequired,
  }).isRequired,
  total: PropTypes.number.isRequired,
};

const defaultProps = {
  paymentPlans: {},
};

function InstallmentsPlanSelectorOv({
  selectedCard,
  onSelectCard,
  onSelectPlan,
  visible,
  paymentPlans,
  handleToggleModal,
  resetInstallmentsPlan,
  selectedInstallmentsPlan,
  total,
}) {
  const { t } = useTranslation('general');
  const { env, features } = useSelector((state) => state.whitelabelConfig);

  if (!visible) return null;

  const selectPlanHandler = (selectedMonths) => {
    handleToggleModal();

    /**
     * When installments plan is set to 1 month payment, reset installments
     */
    if (selectedMonths === 1) {
      resetInstallmentsPlan();
      return;
    }

    onSelectPlan(selectedCard, Number(selectedMonths), paymentPlans);
  };

  if (!paymentPlans.visaMc) return null;

  const selectedCardPlans = paymentPlans[selectedCard];
  const installmentsPlans = Object.keys(selectedCardPlans).map((option) => ({
    months: Number(option),
    monthlyPayment: selectedCardPlans[option].monthlyPayment,
    interest: selectedCardPlans[option].interest,
    interestByMonth: selectedCardPlans[option].interestByMonth,
  }));

  const singlePayment = {
    months: 1,
    monthlyPayment: total,
    interest: 0,
    interestByMonth: 0,
  };

  return (
    <Modal
      title={t('deferred_payment')}
      responsiveSize="L"
      headerColor="primary"
      visible={visible}
      headerButton={false}
      onCloseModal={handleToggleModal}
    >
      <CSSVariablesProvider
        theme={env.theme}
        funnelStyle={features.FUNNEL_STYLE}
        funnelStyleBrandVariation={env.brand.toUpperCase()}
      >
        <Spacing vertical>
          <Spacing vertical size="S">
            <Text weight="semibold" mobileSize="S">
              {t('payment:select_card_type')}
            </Text>

            <Spacing flexGrow>
              <InstallmentsRadio
                variant="visa"
                name="visaMc"
                onClick={onSelectCard}
                checked={selectedCard === 'visaMc'}
              />
              <InstallmentsRadio
                variant="amex"
                name="amex"
                onClick={onSelectCard}
                checked={selectedCard === 'amex'}
              />
            </Spacing>
          </Spacing>
          <Spacing vertical size="S">
            <InstallmentsRadio
              key={`${selectedCard}-1`}
              name={`${selectedCard}-plan`}
              variant="installments"
              installmentsPlan={singlePayment}
              value={1}
              onClick={() => selectPlanHandler(1)}
              checked={selectedInstallmentsPlan.months === 1}
            />
          </Spacing>
          <Spacing vertical size="S">
            <Text weight="semibold" mobileSize="S">
              {t('payment:select_intallments_plan')}
            </Text>

            {installmentsPlans.map((plan) => (
              <InstallmentsRadio
                key={`${selectedCard}-${plan.months}`}
                name={`${selectedCard}-plan`}
                variant="installments"
                installmentsPlan={plan}
                value={plan.months}
                onClick={() => selectPlanHandler(plan.months)}
                checked={selectedInstallmentsPlan.months === plan.months}
              />
            ))}
          </Spacing>
        </Spacing>
      </CSSVariablesProvider>
    </Modal>
  );
}

InstallmentsPlanSelectorOv.propTypes = propTypes;
InstallmentsPlanSelectorOv.defaultProps = defaultProps;

export default InstallmentsPlanSelectorOv;
