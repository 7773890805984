import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon, Spacing } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import InsuranceModal from './InsuranceModal';
import { formatCurrency } from '../utils/Helpers';
import insuranceETN from '../images/brands-unique/etn/insuranceETN.png';
import 'styles/components/CardCheckbox';
import LabeledToggle from '../ui/atoms/LabeledToggle';

const PassengerInsuranceToggle = ({
  passenger,
  insuranceOutgoingUnitAmount,
  insuranceIncomingUnitAmount,
  isExchange,
  wantsOutgoingInsurance,
  wantsIncomingInsurance,
  checked,
  isLoading,
  onChange,
}) => {
  const { t } = useTranslation('purchase');
  const preWantsOutgoingInsurance = useRef(null);
  const preWantsIncomingInsurance = useRef(null);

  useEffect(() => {
    preWantsOutgoingInsurance.current = wantsOutgoingInsurance;
    preWantsIncomingInsurance.current = wantsIncomingInsurance;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSimpleTrip = true;
  const isRoundedTrip = false;

  return (
    <div className="bg-white rounded-[10px] shadow-[0_1px_6px_rgba(35,35,35,0.15)] flex py-[15px] px-[10px] lg:p-[15px] cursor-pointer">
      <Spacing
        size="S"
        justifyContent="space-between"
        alignItems="center"
        mobileAlign={isSimpleTrip ? 'center' : 'flex-start'}
        isResponsive={isRoundedTrip}
        fullWidth
      >
        <Spacing size="S" responsiveSize="XXS" alignItems="center" fullWidth>
          <Icon type={insuranceETN} mobileSize="S" />
          <InsuranceModal />
        </Spacing>

        {isSimpleTrip && (
          <LabeledToggle
            label={t('label.add_trip_insurance', {
              cost: formatCurrency(insuranceOutgoingUnitAmount, 2),
            })}
            checked={checked}
            isLoading={isLoading}
            id={`${passenger}.wantsOutgoingInsurance`}
            onChange={!isLoading && onChange}
            disabled={isExchange && wantsOutgoingInsurance && preWantsOutgoingInsurance.current}
            justifyContent="flex-end"
            fullWidth
          />
        )}

        {isRoundedTrip && (
          <Spacing alignItems="center" justifyContent="space-between" fullWidth>
            {insuranceOutgoingUnitAmount > 0 && (
              <LabeledToggle
                label={t('label.departing_trip', {
                  cost: formatCurrency(insuranceOutgoingUnitAmount, 2),
                })}
                checked={checked}
                isLoading={isLoading}
                id={`${passenger}.wantsOutgoingInsurance`}
                onChange={!isLoading && onChange}
                disabled={isExchange && wantsOutgoingInsurance && preWantsOutgoingInsurance.current}
              />
            )}

            {insuranceIncomingUnitAmount > 0 && (
              <LabeledToggle
                label={t('label.returning_trip', {
                  cost: formatCurrency(insuranceIncomingUnitAmount, 2),
                })}
                checked={checked}
                isLoading={isLoading}
                id={`${passenger}.wantsIncomingInsurance`}
                onChange={!isLoading && onChange}
                disabled={isExchange && wantsOutgoingInsurance && preWantsIncomingInsurance.current}
              />
            )}
          </Spacing>
        )}
      </Spacing>
    </div>
  );
};

PassengerInsuranceToggle.propTypes = {
  passenger: PropTypes.string.isRequired,
  insuranceOutgoingUnitAmount: PropTypes.number.isRequired,
  insuranceIncomingUnitAmount: PropTypes.number,
  isExchange: PropTypes.bool,
  wantsOutgoingInsurance: PropTypes.bool,
  wantsIncomingInsurance: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

export default PassengerInsuranceToggle;
