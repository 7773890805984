/**
 * Creates a new RedeEngine instance.
 * @returns {Object} The RedeEngine instance.
 */
const RedeEngine = () => {
  let tokenizationEndpoint = '';

  /**
   * Initializes the RedeEngine with the given endpoint.
   * @param {Object} config - The configuration object.
   * @param {string} config.tokenizeEndpoint - The endpoint for tokenizing cards.
   */
  const init = ({ tokenizeEndpoint }) => {
    tokenizationEndpoint = tokenizeEndpoint;
  };

  /**
   * Tokenizes a card with the given details.
   * @param {Object} cardDetails - The card details.
   * @param {string} cardDetails.holderName - The name of the card holder.
   * @param {string} cardDetails.cardNumber - The card number.
   * @param {string} cardDetails.expirationMonth - The expiration month of the card.
   * @param {string} cardDetails.expirationYear - The expiration year of the card.
   * @param {string} cardDetails.cardBrand - The brand of the card.
   * @param {string} cardDetails.cvv - The CVV of the card.
   * @returns {Promise<string>} The tokenized card.
   */
  const tokenizeCard = async ({
    holderName,
    cardNumber,
    expirationMonth,
    expirationYear,
    cardBrand,
    cvv,
  }) => {
    const response = await fetch(tokenizationEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        card_holder_name: holderName,
        card_number: cardNumber,
        card_exp_month: expirationMonth,
        card_exp_year: expirationYear,
        card_brand: cardBrand,
        payment_engine: 'rede',
        card_cvv: cvv,
        card_type: 'credit_card',
        monthly_selected_plan: 1,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to tokenize card');
    }

    const data = await response.json();

    return data.rede_token;
  };

  return {
    init,
    tokenizeCard,
  };
};

const redeEngine = RedeEngine();

export default redeEngine;
