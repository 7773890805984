import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import adyenLogo from 'images/payment/adyen.svg';
import useAdyen from 'hooks/libs/useAdyen';
import SaveCardCheckbox from '../SaveCardCheckbox';
import Installments from '../Installments';
import LoyaltyLink from '../../../ui/atoms/LoyaltyLink';
import 'styles/components/AdyenForm';
import 'styles/components/purchase/PaymentOptions';
import CardsLogos from '../CardsLogos';

/**
 * Renders the Adyen payment form.
 *
 * This component is responsible for displaying the Adyen payment form, including the option to save the card,
 * the Adyen logo, and the form inputs for payment processing. It also conditionally renders the accepted cards
 * logos and the installments option based on the whitelabel configuration.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.showAdyenForm - Indicates whether to show the Adyen form.
 * @param {boolean} props.showCheckbox - Indicates whether to show the save card checkbox.
 * @returns {React.ReactElement} The rendered component.
 */
const AdyenForm = ({ showAdyenForm, showCheckbox }) => {
  const { t } = useTranslation();
  const { features } = useSelector((state) => state.whitelabelConfig);
  const adyenElement = useRef(null);
  const siemprePlus = useSelector((state) => state.siemprePlus);

  useAdyen(adyenElement, showAdyenForm);

  return (
    <>
      {showAdyenForm && (
        <Spacing vertical>
          {features.SHOW_CREDIT_DEBIT_LOGOS && <Text size="S">{t('purchase:accepted_cards')}</Text>}
          {features.SHOW_ADYEN_CARD_LOGOS && (
            <CardsLogos availableCards={features.ADYEN_AVAILABLE_CARD_BRANDS} />
          )}

          <Spacing vertical size="XS">
            <div className="adyen-form-inputs" ref={adyenElement} />
            <div className="adyen-logo-container">
              {showCheckbox && <SaveCardCheckbox />}
              <img src={adyenLogo} height="20px" width="60px" />
            </div>
          </Spacing>
          {features.INSTALLMENTS_ENABLED && <Installments />}
        </Spacing>
      )}
      <LoyaltyLink id="link-home" visible={!siemprePlus.get('user')} />
    </>
  );
};

AdyenForm.propTypes = {
  showAdyenForm: PropTypes.bool,
  showCheckbox: PropTypes.bool,
};

AdyenForm.defaultProps = {
  showAdyenForm: false,
  showCheckbox: false,
};

export default AdyenForm;
