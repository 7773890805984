import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { trackEvent } from 'user-analytics';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '@/actions';
import { setFrequentPassengers as setFrequentPassengersAction } from '@/actions/userPreferences';
import useUserPreferences from 'hooks/useUserPreferences';
import { adaptPassengersData } from 'utils/userPreferences';
import userPreferencesService from 'services/userPreferences';
import { change } from 'redux-form';
import FrequentPassengersContext from './FrequentPassengersContext';
/**
 * FrequentPassengersProvider component.
 * Manages state and operations for frequent passengers functionality.
 * @param {object} props - children, formPassengersState.
 * @param {React.ReactNode} props.children - Child components to be wrapped.
 */
const FrequentPassengersProvider = ({ children }) => {
  const { passengers } = useUserPreferences();
  const formPassengersState = useSelector((state) => state.form.passengers.values.passengers);
  const dispatch = useDispatch();
  const adaptedPassengers = adaptPassengersData(passengers).map((passenger) => {
    const initialPassengerMatch = formPassengersState
      .filter((passenger) => passenger.firstName?.trim() !== '')
      .find((initialPassenger) => initialPassenger.id === passenger.id);
    const initialPassengerIndexMatch = formPassengersState
      .filter((passenger) => passenger.firstName?.trim() !== '')
      .findIndex((initialPassenger) => initialPassenger.id === passenger.id);
    return {
      ...passenger,
      selectedForPassenger: initialPassengerMatch ? initialPassengerIndexMatch + 1 : null,
      isSelected: Boolean(initialPassengerMatch),
    };
  });
  const [frequentPassengers, setFrequentPassengers] = useState(adaptedPassengers);
  const frequentPassengersRef = useRef();
  frequentPassengersRef.current = frequentPassengers;
  const { t } = useTranslation();
  /*
   * The array looks like this:
   * [
   *  {
   *    passenger: {} // frequent passenger object
   *    timeoutId,
   *  },
   * ]
   */
  const pendingPassengerDeletions = useRef([]);

  const trackFrequentPassengerSelected = (passenger, from) => {
    trackEvent('Frequent Passenger Selected', {
      Passenger: {
        'First Name': passenger.firstName,
        'Last Name': passenger.lastName,
        'Email': passenger.email || 'N/A',
        'Passenger Number': passenger.selectedForPassenger,
      },
      From: from,
    });
  };

  useEffect(() => {
    const currentRoute = window.location.pathname;
    const isPassengersRoute = currentRoute.includes('/passengers');
    if (!isPassengersRoute) {
      return;
    }

    if (passengers.length) {
      trackEvent('Frequent Passengers Showed', {
        'Passengers Count': passengers.length,
        'Passengers': passengers.map((passenger) => ({
          'First Name': passenger.firstName,
          'Last Name': passenger.lastName,
          'Email': passenger.email || 'N/A',
        })),
      });
    }

    adaptedPassengers.forEach((passenger) => {
      if (passenger.isSelected) {
        trackFrequentPassengerSelected(passenger, 'Initial Selection');
      }
    });

    dispatch(setFrequentPassengersAction(adaptedPassengers));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removePendingPassengerDeletion = (id) => {
    pendingPassengerDeletions.current = pendingPassengerDeletions.current.filter(
      (deletion) => deletion.passenger.id !== id,
    );
  };

  const undoDeleteFrequentPassenger = (id) => {
    const deletion = pendingPassengerDeletions.current.find(
      (deletion) => deletion.passenger.id === id,
    );
    clearTimeout(deletion.timeoutId);
    /**
     * Since this method is called from a timeout, we need to use the ref to have access to the latest state.
     * Otherwise, we would be using the stale state from when the timeout was set.
     */
    setFrequentPassengers([...frequentPassengersRef.current, deletion.passenger]);
    trackEvent('Frequent Passenger Delete Undone', {
      Passenger: {
        'First Name': deletion.passenger.firstName,
        'Last Name': deletion.passenger.lastName,
        'Email': deletion.passenger.email || 'N/A',
      },
    });
    removePendingPassengerDeletion(id);
  };

  /**
   * Removes a frequent passenger from the list.
   * @param {string} id - ID of the passenger to remove.
   */
  const removeFrequentPassenger = (id) => {
    const passengerToDelete = frequentPassengers.find((passenger) => passenger.id === id);
    setFrequentPassengers(frequentPassengers.filter((passenger) => passenger.id !== id));
    trackEvent('Frequent Passenger Deleted', {
      'Passenger': {
        'First Name': passengerToDelete.firstName,
        'Last Name': passengerToDelete.lastName,
        'Email': passengerToDelete.email || 'N/A',
      },
      'Passenger Number': passengerToDelete.selectedForPassenger,
      'Selected': passengerToDelete.isSelected,
    });
    pendingPassengerDeletions.current.push({
      passenger: passengerToDelete,
      timeoutId: setTimeout(async () => {
        try {
          await userPreferencesService.deleteFrequentPassenger(id);
          removePendingPassengerDeletion(id);
          /**
           * Update store with the latest state.
           */
          dispatch(setFrequentPassengersAction(frequentPassengersRef.current));
        } catch (error) {
          undoDeleteFrequentPassenger(id);
          dispatch(setAlert('', 'error', t('errors:could_not_delete_frequent_passenger')));
        }
      }, 10000),
    });
  };

  /**
   * Selects a frequent passenger for a specific passenger index.
   * @param {string} id - ID of the frequent passenger to select.
   * @param {number} passengerIndex - Index of the passenger to apply the selection to.
   */
  const selectFrequentPassenger = (id, passengerIndex, from = '') => {
    /**
     * @todo Update passengers form
     */
    const newFrequentPassengers = frequentPassengers
      .map((passenger) => {
        if (passenger.selectedForPassenger === passengerIndex) {
          return { ...passenger, isSelected: false, selectedForPassenger: null };
        }
        return passenger;
      })
      .map((passenger) => {
        if (passenger.id === id) {
          return { ...passenger, isSelected: true, selectedForPassenger: passengerIndex };
        }
        return passenger;
      });

    setFrequentPassengers(newFrequentPassengers);

    dispatch(setFrequentPassengersAction(newFrequentPassengers));

    const selectedPassenger = newFrequentPassengers.find((passenger) => passenger.id === id);

    trackFrequentPassengerSelected(selectedPassenger, from);

    // Update the form state with the selected passenger
    if (selectedPassenger) {
      dispatch(
        change('passengers', `passengers[${passengerIndex - 1}]`, {
          ...formPassengersState[passengerIndex - 1],
          firstName: selectedPassenger.firstName,
          lastName: selectedPassenger.lastName,
          email: selectedPassenger.email,
          // Add any other passenger fields you need to copy
        }),
      );
    }
  };

  const contextValue = {
    frequentPassengers,
    removeFrequentPassenger,
    selectFrequentPassenger,
    pendingPassengerDeletions,
    undoDeleteFrequentPassenger,
  };

  return (
    <FrequentPassengersContext.Provider value={contextValue}>
      {children}
    </FrequentPassengersContext.Provider>
  );
};

FrequentPassengersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FrequentPassengersProvider;
