import React from 'react';
import { Spacing, Text } from '@reservamos/elements';
import { Field } from 'redux-form';
import { fieldRenderer, FilterableSelectRenderer } from 'utils/formRenderers';
import { useTranslation } from 'react-i18next';
import {
  city,
  country,
  neighborhood,
  streetName,
  streetNumber,
  zipCode,
} from 'utils/formValidations';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';

/**
 * AddressFields component.
 * Renders a form section with address-related input fields using redux-form.
 * @returns {JSX.Element} A group of address form fields including zip code, street name,
 * street number, neighborhood, and city.
 */
const AddressFields = () => {
  const { t } = useTranslation('payment');
  const { purchaserCountries } = useWhitelabelEnvs();
  if (!purchaserCountries) return null;

  return (
    <Spacing size="S" isResponsive vertical>
      <Text size="M" weight="bold">
        {t('label.address')}
      </Text>
      <Spacing size="S" isResponsive flexGrow>
        <Field
          id="zipCode"
          type="text"
          name="addressAttributes.zipCode"
          label={t('label.zip_code')}
          placeholder={t('label.zip_code')}
          component={fieldRenderer}
          validate={zipCode}
        />
        <Field
          id="streetName"
          type="text"
          name="addressAttributes.street"
          label={t('label.street_name')}
          placeholder={t('label.street_name')}
          component={fieldRenderer}
          validate={streetName}
        />
        <Field
          id="streetNumber"
          type="text"
          name="addressAttributes.number"
          label={t('label.street_number')}
          placeholder={t('label.street_number')}
          component={fieldRenderer}
          validate={streetNumber}
        />
      </Spacing>
      <Spacing size="S" isResponsive flexGrow>
        <Field
          id="neighborhood"
          type="text"
          name="addressAttributes.neighborhood"
          label={t('label.neighborhood')}
          placeholder={t('label.neighborhood')}
          component={fieldRenderer}
          validate={neighborhood}
        />
        <Field
          id="city"
          type="text"
          name="addressAttributes.city"
          label={t('label.city')}
          placeholder={t('label.city')}
          component={fieldRenderer}
          validate={city}
        />
        <Field
          id="country"
          name="addressAttributes.countryCode"
          label={t('label.country')}
          placeholder={t('label.country')}
          options={purchaserCountries}
          noOptionsText={t('general:no_options_found')}
          component={FilterableSelectRenderer}
          validate={country}
        />
      </Spacing>
    </Spacing>
  );
};

export default AddressFields;
