import { useSelector } from 'react-redux';

/**
 * useTrips hook.
 * @returns {object} props - trips, getDepartureTripsById, getReturnTripsById
 * @returns {object} props.trips - Object with all trips
 * @returns {Function} props.getDepartureTripsById - Function to get departure trips by ID
 * @returns {Function} props.getReturnTripsById - Function to get return trips by ID
 */
function useTrips() {
  const trips = useSelector((state) => state.trips.toJS());

  /**
   * Gets departure trips by search ID
   * @param {string} searchId - Search ID
   * @returns {object} Departure trips
   */
  const getDepartureTripsById = (searchId) => {
    return trips[searchId].buses.trips;
  };

  /**
   * Gets return trips by search ID
   * @param {string} searchId - Search ID
   * @returns {object} Return trips
   */
  const getReturnTripsById = (searchId) => {
    return trips[searchId].buses.trips;
  };

  return { trips, getDepartureTripsById, getReturnTripsById };
}

export default useTrips;
