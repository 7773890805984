import React from 'react';
import PropTypes from 'prop-types';
import { Text, Spacing, ToggleSwitch } from '@reservamos/elements';

const LabeledToggle = ({
  label,
  checked,
  isLoading,
  id,
  disabled,
  onChange,
  justifyContent,
  fullWidth,
}) => {
  return (
    <Spacing
      size="S"
      responsiveSize="XS"
      alignItems="center"
      justifyContent={justifyContent}
      fullWidth={fullWidth}
    >
      <Text size="S" mobileSize="XS" whiteSpace="nowrap">
        {label}
      </Text>

      <ToggleSwitch
        color="success"
        checked={checked}
        isLoading={isLoading}
        id={id}
        onChange={!isLoading && onChange}
        fillBackground
        size="S"
        disabled={disabled}
      />
    </Spacing>
  );
};

LabeledToggle.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  isLoading: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  justifyContent: PropTypes.string,
  fullWidth: PropTypes.bool,
};

LabeledToggle.defaultProps = {
  label: 'Label',
  checked: false,
  isLoading: false,
  id: 'toggle-switch',
  disabled: false,
  onChange: null,
  justifyContent: 'initial',
  fullWidth: false,
};

export default LabeledToggle;
