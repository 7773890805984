import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { updateFirstPassenger } from '@/actions/siemprePlus';
import { change } from 'redux-form';
import { trackEvent } from 'user-analytics';
import { useDispatch, useSelector } from 'react-redux';
import { Spacing, Text, Icon } from '@reservamos/elements';
import useFrequentPassengersContext from 'context/FrequentPassengers';
import FrequentPassengerModal from 'components/FrequentPassengerModal';
import PassengerBoxCollapse from 'components/PassengerBoxCollapse';
import FrequentPassengerNav from 'components/FrequentPassengerNav';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import useExperimentalFeature from 'hooks/useExperimentalFeature';
import PassengerInsuranceToggle from 'components/PassengerInsuranceToggle';
import LoyaltyHolder from '../../../ui/atoms/LoyaltyHolder';
import usePurchase from '../../../hooks/store/usePurchase';
import PassengersHeading from '../../../ui/molecules/PassengersHeading';
import useLoyaltyPrograms from '../../../loyalty/context/useLoyaltyPrograms';
import { isPetFriendlySeat } from '../../../utils/purchase/seats';
import PetsPassengersForm from './PetPassengersForm';
import PersonPassengersForm from './PersonPassengersForm';
import LabelSwitch from '../../../ui/atoms/LabelSwitch';
import parsePassenger from '../../../utils/purchase/parsePassenger';
/**
 * Render the right element over each passenger.
 * Could render the siempre plus logo and holder label or pet friendly switch
 * @param {Object} props - Component props
 * @param {boolean} props.holderEditingEnabled - Indicates if the passenger logged is being edited
 * @param {boolean} props.showPetFriendly - Indicates if the pet friendly switch should be shown
 * @param {boolean} props.isPet - Indicates if the passenger is a pet
 * @param {number} props.petSeats - Number of pet seats available
 * @param {string} props.passenger - Passenger index to be used in the redux form
 * @param {boolean} props.showLoyaltyHolder - Indicates if the loyalty holder should be shown
 * @param {string} props.loyaltyHolderName - Name of the loyalty holder
 * @param {Function} props.showPetFriendlySeatsWarn - Function to show the pet friendly seats warning
 * @returns JSX Element
 */
const RightContent = ({
  holderEditingEnabled,
  showPetFriendly,
  isPet,
  petSeats,
  passenger,
  showLoyaltyHolder,
  loyaltyHolderName,
  showPetFriendlySeatsWarn,
}) => {
  const { t } = useTranslation('passengers');
  const dispatch = useDispatch();
  const form = useSelector((state) => state.form);
  const {
    passengers: {
      values: { passengers: formPassengers },
    },
  } = form;

  /**
   * Returns true if the pet friendly switch can be selected
   * @param {Number} petSeats - Number of peats seats available.
   * @param {*} checked - Indicates if the switch is already checked.
   * @returns
   */
  const canSelectPet = (petSeats, checked) => {
    const adultsCount = formPassengers.reduce(
      (acc, curr) =>
        curr.busCategory !== 'minor' && curr.busCategory !== 'pet_friendly' ? acc + 1 : acc,
      0,
    );
    const petsTaken = formPassengers.filter((curr) => curr.busCategory === 'pet_friendly').length;
    if ((adultsCount - 1 < petsTaken + 1 || petsTaken === petSeats) && !checked) {
      return false;
    }
    return true;
  };

  /**
   * Handles the click for changes on the pet friendly switch
   * Enabled when passenger busCategory is pet_friendly
   * @param {boolean} checked - Switch is on, based on pet_friendly busCategory selected
   * @param {Object} passenger - Current passenger object
   */
  const handlePetSwitch = (checked, passenger) => {
    if (!canSelectPet(petSeats, checked)) {
      showPetFriendlySeatsWarn();
      return;
    }
    if (checked) {
      dispatch(change('passengers', passenger, 'general'));
    } else {
      dispatch(change('passengers', passenger, 'pet_friendly'));
    }
  };

  if (showLoyaltyHolder) {
    return <LoyaltyHolder loyaltyHolderName={loyaltyHolderName} hideLabel={holderEditingEnabled} />;
  }

  if (showPetFriendly) {
    return (
      <LabelSwitch
        label={t('pet_switch')}
        justifyContent="flex-end"
        onChange={() => handlePetSwitch(isPet, `${passenger}.busCategory`)}
        checked={isPet}
      />
    );
  }

  return null;
};

/**
 * StaticPassengersForm component.
 * Renders a form for managing passenger information in a static manner.
 * @param {object} props - Component props.
 * @param {object} props.fields - Redux Form fields object.
 * @param {boolean} props.autofillEnabled - Whether autofill is enabled.
 * @param {boolean} props.updatingCategories - Whether categories are being updated.
 * @param {Array} props.busCategories - Available bus categories.
 * @param {Function} props.toggleAutofill - Function to toggle autofill.
 * @param {number} props.insuranceOutgoingUnitAmount - Insurance amount for outgoing trip.
 * @param {number} props.insuranceIncomingUnitAmount - Insurance amount for incoming trip.
 * @param {boolean} props.isExchange - Whether this is an exchange booking.
 * @param {boolean} props.isLogged - Whether user is logged in.
 * @param {boolean} props.isRoundTrip - Whether this is a round trip.
 * @param {boolean} props.isOpenTicket - Whether this is an open ticket.
 * @param {boolean} props.user - User information.
 * @param {Function} props.showPetFriendlySeatsWarn - Function to show pet friendly seats warning.
 * @param {boolean} props.showPurchaserForm - Whether to show purchaser form.
 * @param {boolean} props.isAccountThePassenger - Whether account holder is a passenger.
 * @param {string} props.loyaltyHolderName - Name of loyalty program holder.
 * @param {Array} props.initialValues - Initial form values.
 * @param {Function} props.autofillFirstPassenger - Function to autofill first passenger.
 */
const StaticPassengersForm = ({
  fields,
  busCategories,
  autofillEnabled,
  updatingCategories,
  toggleAutofill,
  insuranceOutgoingUnitAmount,
  insuranceIncomingUnitAmount,
  isExchange,
  isLogged,
  isRoundTrip,
  isOpenTicket,
  user,
  showPetFriendlySeatsWarn,
  showPurchaserForm,
  isAccountThePassenger,
  loyaltyHolderName,
  initialValues,
  autofillFirstPassenger,
}) => {
  const showFrequentPassengers = useExperimentalFeature('SHOW_FREQUENT_PASSENGERS');
  const features = useWhitelabelFeatures();
  const initialFields = useRef(null);
  const purchase = usePurchase();
  const { selectedLoyaltyProgram, userIsLoggedInWithAnyLoyaltyProgram } = useLoyaltyPrograms();
  const [holderEditingEnabled, setHolderEditingEnabled] = useState(false);
  const [showFrequentPassengersModal, setShowFrequentPassengersModal] = useState({
    opened: false,
    passenger: {
      index: -1,
      category: '',
    },
  });
  const { frequentPassengers } = useFrequentPassengersContext();
  /**
   * Sets the initial values of passengers in a static ref array
   * This field is used for avoid render without initializing and for load initial data after authenticate
   */
  const setInitialFields = () => {
    initialFields.current = [...initialValues];
  };

  useEffect(() => {
    if (!initialFields.current) setInitialFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLogged) {
      setInitialFields();
      autofillFirstPassenger(
        initialValues[0].firstName,
        initialValues[0].lastName,
        initialValues[0].secondLastName,
        initialValues[0].documentType,
        initialValues[0].documentId,
        initialValues[0].email,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  const { t } = useTranslation('passengers');

  /**
   * Handles the name change switch click event.
   * When clicked, if disabled it sets default values for firstName and lastName
   * @param {SyntheticEvent} e - Click event
   */
  const handleHolderEditing = (e) => {
    if (!e.target.checked) {
      fields.get(0).firstName = initialFields.current[0]?.firstName;
      fields.get(0).lastName = initialFields.current[0]?.lastName;
    }
    setHolderEditingEnabled((prev) => !prev);
    if (holderEditingEnabled) updateFirstPassenger(parsePassenger(user));
  };

  useEffect(() => {
    if (!isLogged) return;
    if (isAccountThePassenger) {
      setHolderEditingEnabled(false);
    } else {
      setHolderEditingEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountThePassenger]);

  if (isExchange && !initialFields.current) return null;

  const shouldDisableFirstPassengerEdition =
    userIsLoggedInWithAnyLoyaltyProgram && purchase.availableWallets.length;

  const petFriendlyQuantity = fields.reduce((acc, curr, index) => {
    const passengerFields = fields.get(index);
    const { seats } = passengerFields;
    if (isPetFriendlySeat(seats?.departure?.category)) {
      return acc + 1;
    }
    return acc + 0;
  }, 0);

  /**
   * Determines if both departure and return seats are pet seats.
   * @param {Object} seats - The seats object containing departure and return seat information.
   * @returns {boolean} - True if both seats are pet seats or if it isnt a roundtrip to evade entering the function
   */
  const validPetFriendlySeatsForRoundTrip = (seats) => {
    if (!isRoundTrip) return true;
    return (
      isPetFriendlySeat(seats?.departure?.category) && isPetFriendlySeat(seats?.return?.category)
    );
  };

  /**
   * Determines if the pet-friendly option should be shown for a passenger.
   *
   * @param {object} passengerFields - The fields of the passenger.
   * @param {boolean} isFirstPassenger - Indicates if the passenger is the first.
   * @returns {boolean} - True if the pet-friendly option should be shown, false otherwise.
   */
  const shouldShowPetFriendly = (passengerFields, isFirstPassenger) => {
    const { seats } = passengerFields;
    return (
      isPetFriendlySeat(seats?.departure?.category) &&
      !isFirstPassenger &&
      validPetFriendlySeatsForRoundTrip(seats) &&
      Boolean(busCategories.some(({ type }) => features.SPECIAL_CATEGORIES.includes(type)))
    );
  };
  const passengers = fields.map((passenger, index) => {
    const passengerFields = fields.get(index);
    const isFirstPassenger = index === 0;
    const showLoyaltyHolder =
      loyaltyHolderName && isFirstPassenger && userIsLoggedInWithAnyLoyaltyProgram;

    const { seats, departureSeats, returnSeats } = passengerFields;

    const isPet =
      passengerFields.busCategory === 'pet_friendly' && validPetFriendlySeatsForRoundTrip(seats);
    const showPetFriendly = shouldShowPetFriendly(passengerFields, isFirstPassenger);

    const exchangeInsuranceValidation =
      !isExchange || (isExchange && features.CAN_ADD_EXCHANGE_INSURANCE);

    /**
     * Defines which form it should render
     * Currently there are two forms, pet or person.
     * @returns Passenger form
     */
    const passengerName = passengerFields.firstName
      ? `${passengerFields.firstName} ${passengerFields.lastName}`
      : '';

    /**
     * Determines if frequent passengers section should be shown.
     * @type {boolean}
     */
    const validateFrequentPassengers = showFrequentPassengers && Boolean(frequentPassengers.length);

    const renderForm = () => {
      return (
        <Spacing vertical>
          <PassengerBoxCollapse
            name={passengerName}
            typePassenger={passengerFields.busCategory}
            numberPassenger={index + 1}
            numberSeat={passengerFields.seats?.departure?.number}
            returnSeat={passengerFields.seats?.return?.number}
          >
            <Spacing size="S" vertical>
              {validateFrequentPassengers && (
                <Spacing
                  responsiveSize="XS"
                  size="S"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  fullWidth
                >
                  <Spacing size="XS" vertical fullWidth>
                    <div className="flex -mb-1 gap-1">
                      <Icon type="emojiSparkles" size="M" />
                      <Text size="S" color="grayMedium">
                        {t('frequent_passengers.pick_a_frequent_passenger')}
                      </Text>
                    </div>
                    <Spacing
                      responsiveSize="S"
                      justifyContent="space-between"
                      alignItems="center"
                      fullWidth
                    >
                      <FrequentPassengerNav passengerIndex={index + 1} />
                      <button
                        onClick={() => {
                          setShowFrequentPassengersModal({
                            opened: true,
                            passenger: { index: index + 1, category: passengerFields.busCategory },
                          });
                          trackEvent('Frequent Passengers Modal Opened', {
                            'Passenger Number': index + 1,
                          });
                        }}
                        type="button"
                        className="bg-white shadow-md px-[7px] md:px-[10px] py-[5px] rounded-full flex items-center gap-1 md:gap-2"
                      >
                        <Text>{t('frequent_passengers.other_passenger')}</Text>
                        <Icon
                          size="S"
                          type="ChevronDown"
                          color="grayMedium"
                          hasMargin
                          marginSide="left"
                        />
                      </button>
                    </Spacing>
                  </Spacing>
                </Spacing>
              )}
              {isPet ? (
                <PetsPassengersForm passenger={passenger} />
              ) : (
                <PersonPassengersForm
                  passenger={passenger}
                  initialFields={initialFields}
                  fields={fields}
                  handleHolderEditing={handleHolderEditing}
                  holderEditingEnabled={holderEditingEnabled}
                  isExchange={isExchange}
                  isLogged={isLogged}
                  showLoyaltyHolder={showLoyaltyHolder}
                  isFirstPassenger={isFirstPassenger}
                  passengerFields={passengerFields}
                  index={index}
                  busCategories={busCategories}
                  updatingCategories={updatingCategories}
                  insuranceIncomingUnitAmount={insuranceIncomingUnitAmount}
                  insuranceOutgoingUnitAmount={insuranceOutgoingUnitAmount}
                  autofillEnabled={autofillEnabled}
                  isOpenTicket={isOpenTicket}
                  loyaltyHolderName={loyaltyHolderName}
                  disableFirstPassenger={shouldDisableFirstPassengerEdition}
                />
              )}
            </Spacing>
          </PassengerBoxCollapse>

          {features.USE_NEW_INSURANCE_TOGGLE_PER_PASSENGER &&
            exchangeInsuranceValidation &&
            features.PASSENGERS_INSURANCE_ENABLED &&
            (insuranceIncomingUnitAmount > 0 || insuranceOutgoingUnitAmount > 0) && (
              <PassengerInsuranceToggle
                passenger={passenger}
                insuranceOutgoingUnitAmount={insuranceOutgoingUnitAmount}
                insuranceIncomingUnitAmount={insuranceIncomingUnitAmount}
                isExchange={isExchange}
                wantsOutgoingInsurance={isExchange && fields.get(index).wantsOutgoingInsurance}
                wantsIncomingInsurance={isExchange && fields.get(index).wantsIncomingInsurance}
              />
            )}
        </Spacing>
      );
    };

    /**
     * [departure/return]Seats are objects added to passengerField at utils/passengers:getInitialPassengers.
     * This fields are added only if the trip has connections.
     *
     * So basically, if the trip has connections, we use the seats from the [departure/return]Seats object.
     * If not, we use the seats object (which works well with trips without connections).
     *
     * The difference between [departure/return]Seats and seats, is that seats only contains one seat per trip (one for departure and one for return).
     * However, when a trip hasConnections, we can have more than one seat for departure and return.
     * [departure/return]Seats takes this in mind.
     */
    const departureSeatsArray = Object.values(departureSeats || {});
    const returnSeatsArray = Object.values(returnSeats || {});
    const seatsObject = departureSeatsArray.length
      ? [...departureSeatsArray, ...returnSeatsArray]
      : [seats?.departure, seats?.return];
    /**
     * @todo Apply refactor for hybrid trips (direct departure + return with connections, or vice versa)
     *
     * The code above supposes that if the departure trip has connections, the return trip will have connections too.
     * This is true, since we don't have hybrid trips yet.
     *
     * When we have hybrid trips, we need to check if the departure trip has connections, and if the return trip has connections.
     * The following code is a suggestion for this refactor:
     *
     * ```js
     * const departureSeatsArray = Object.values(departureSeats || {});
     * const returnSeatsArray = Object.values(returnSeats || {});
     * const departureSeats = departureTrip.hasConnections ? departureSeatsArray : [seats?.departure];
     * const returnSeats = returnTrip.hasConnections ? returnSeatsArray : [seats?.return];
     * const seatsObject = [...departureSeats, ...returnSeats]
     * ```
     */

    return (
      <div key={passenger}>
        <Spacing vertical size="S">
          {!showFrequentPassengers && (
            <PassengersHeading
              category={passengerFields.busCategory}
              title={
                isPet
                  ? t('passengers:type.pet_friendly')
                  : t('passengers:label.passenger', { index: index + 1 })
              }
              seats={seatsObject}
              rightContent={
                <RightContent
                  showLoyaltyHolder={showLoyaltyHolder}
                  holderEditingEnabled={holderEditingEnabled}
                  showPetFriendly={showPetFriendly}
                  isPet={isPet}
                  petSeats={petFriendlyQuantity}
                  passenger={passenger}
                  loyaltyHolderName={selectedLoyaltyProgram}
                  showPetFriendlySeatsWarn={showPetFriendlySeatsWarn}
                />
              }
            />
          )}
          {renderForm()}
        </Spacing>
      </div>
    );
  });

  const showPurchaserPassengerSwitch = !isExchange && showPurchaserForm;

  return (
    <Spacing vertical>
      {showPurchaserForm && (
        <Spacing vertical size="XS">
          <Text size="L" weight="bold">
            {t('passengers:label.passengers')}
          </Text>
          {showPurchaserPassengerSwitch && (
            <LabelSwitch
              checked={autofillEnabled}
              onChange={toggleAutofill}
              id="check-passengers"
              label={t('passengers:is_the_buyer_a_passenger')}
            />
          )}
        </Spacing>
      )}
      <Spacing vertical>
        <div className="flex flex-row justify-between">
          <Text size="L" weight="bold">
            {t('passengers:label.who_is_traveling')}
          </Text>
          <Text>{t('passengers:label.on_board', { number: initialValues.length })}</Text>
        </div>
        <Spacing vertical size="XXL">
          {passengers}
        </Spacing>
      </Spacing>
      {showFrequentPassengersModal.opened && (
        <FrequentPassengerModal
          passengerIndex={showFrequentPassengersModal.passenger.index}
          typePassenger={showFrequentPassengersModal.passenger.category}
          onClose={() => {
            setShowFrequentPassengersModal({
              opened: false,
              passenger: { index: -1, category: '' },
            });
            trackEvent('Frequent Passengers Modal Closed', {
              'Passenger Number': showFrequentPassengersModal.passenger.index,
            });
          }}
        />
      )}
    </Spacing>
  );
};

RightContent.propTypes = {
  showLoyaltyHolder: PropTypes.bool.isRequired,
  loyaltyHolderName: PropTypes.string,
  holderEditingEnabled: PropTypes.bool.isRequired,
  showPetFriendly: PropTypes.bool.isRequired,
  isPet: PropTypes.bool.isRequired,
  passenger: PropTypes.object.isRequired,
  petSeats: PropTypes.number,
  showPetFriendlySeatsWarn: PropTypes.func,
};

StaticPassengersForm.propTypes = {
  fields: PropTypes.object.isRequired,
  autofillEnabled: PropTypes.bool.isRequired,
  updatingCategories: PropTypes.bool.isRequired,
  busCategories: PropTypes.array,
  toggleAutofill: PropTypes.func.isRequired,
  insuranceOutgoingUnitAmount: PropTypes.number,
  insuranceIncomingUnitAmount: PropTypes.number,
  isExchange: PropTypes.bool,
  isLogged: PropTypes.bool,
  isRoundTrip: PropTypes.bool,
  isOpenTicket: PropTypes.bool,
  user: PropTypes.bool,
  showPetFriendlySeatsWarn: PropTypes.func,
  showPurchaserForm: PropTypes.bool,
  isAccountThePassenger: PropTypes.bool,
  loyaltyHolderName: PropTypes.string,
  initialValues: PropTypes.array,
  autofillFirstPassenger: PropTypes.func,
};

StaticPassengersForm.defaultProps = {
  busCategories: [],
  isExchange: false,
  isRoundTrip: false,
};

export default StaticPassengersForm;
