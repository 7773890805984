import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import mastercard from 'images/payment/mastercard.svg';
import visa from 'images/payment/visa.svg';
import americanexpress from 'images/payment/americanexpress.svg';
import brazilCards from 'images/payment/brazil-cards.svg';
import 'styles/components/purchase/InstallmentsRadio';
import { getCurrencySuffix } from 'utils/currency';
import { useSelector } from 'react-redux';
import { Text, Currency } from '@reservamos/elements';

const propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['text', 'installments', 'visa', 'amex']),
  labelText: PropTypes.string,
  installmentsPlan: PropTypes.shape({
    months: PropTypes.number,
    monthlyPayment: PropTypes.number,
    interestByMonth: PropTypes.number,
  }),
  onClick: PropTypes.func.isRequired,
};

const defaultProps = {
  variant: 'text',
  labelText: '',
  installmentsPlan: {},
};

function InstallmentsRadio({ name, variant, labelText, installmentsPlan, checked, onClick }) {
  const { t } = useTranslation('payment');
  const { env, features } = useSelector((state) => state.whitelabelConfig);

  const renderLabel = () => {
    const { months, monthlyPayment, interestByMonth } = installmentsPlan;
    let cards = [visa, mastercard];
    let customClass = '';
    const langPt = env.lang.default.startsWith('pt');

    if (langPt) {
      cards = [brazilCards];
      customClass = 'pt';
    }

    if (variant === 'amex') {
      cards = [americanexpress];
    }

    switch (variant) {
      case 'text':
        return (
          <Text size="S" weight="bold">
            {labelText}
          </Text>
        );

      case 'installments':
        if (features.SHOW_INSTALLMENTS_INTEREST) {
          return (
            <Text size="S" weight="bold">
              {`${months}x ${t('payments_of')}`}
              <Currency price={monthlyPayment} currency={getCurrencySuffix()} size="S" />
              <Text size="S" weight="regular">{` (${t('interest_of', { interestByMonth })})`}</Text>
            </Text>
          );
        }

        return (
          <Text size="S" weight="bold">
            {`${months} ${t('payments_of')} `}
            <Currency
              weight="regular"
              price={monthlyPayment}
              currency={getCurrencySuffix()}
              size="S"
            />
          </Text>
        );

      case 'visa':
        return (
          <div className="radio-button-label-img">
            {cards.map((card) => (
              <img key={card} src={card} className={customClass} />
            ))}
          </div>
        );

      case 'amex':
        return (
          <div className="radio-button-label-img">
            {cards.map((card) => (
              <img key={card} src={card} />
            ))}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="radio-button-container">
      <label className={`installments-radio ${checked ? 'active' : ''}`}>
        <div className="radio-button">
          {renderLabel()}

          <div className="radio-button-check">
            <input type="radio" checked={checked} onClick={() => onClick(name)} />
            <span className="icon" />
          </div>
        </div>
      </label>
    </div>
  );
}

InstallmentsRadio.propTypes = propTypes;
InstallmentsRadio.defaultProps = defaultProps;

export default InstallmentsRadio;
