import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spacing, Text, Icon, Box, BlushingBackground } from '@reservamos/elements';
import { useDispatch } from 'react-redux';
import {
  setPassengers,
  updateBulkPurchaseTotal,
  updatePaymentPlans,
  selectInstallmentsPlan,
} from '@/actions/purchase';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import { purchaseTotalByCategory } from 'utils/Reserbus';
import usePurchase from '../../hooks/store/usePurchase';
import passengerTypes from '../../config/passengerTypes';
import PassengerQuantitySelector from '../../ui/atoms/PassengerQuantitySelector';

const PASSENGER_STRUCTURE = {
  lastName: 'NA',
  gender: '',
  phoneCountry: 'MX',
  name: 'NA',
  wantsOutgoingInsurance: false,
  phone: 'NA',
  wantsIncomingInsurance: false,
  secondLastName: '',
  documentType: null,
  documentId: 'NA',
  nationalityId: 1,
  dateOfBirth: null,
  phoneCode: '52',
  firstName: 'NA',
  email: undefined,
  isoCountryCode: null,
  category: 'adult',
};

/**
 * PassengerSelectorBox component.
 * Renders a passenger selector for different categories for bulk purchases.
 */
function PassengerSelectorBox() {
  const { t } = useTranslation('passengers');
  const dispatch = useDispatch();
  const purchase = usePurchase();
  const {
    token,
    passengers,
    departs,
    passengerSelection,
    isUpdating,
    selectedInstallmentsPlan,
    paymentPlans,
    total,
  } = purchase;
  const { passengerTypes: rawCategoryDetails, prices } = departs.fragments[0];

  // changes the category type to pcd if it is special
  // because ui purposes expects pcd
  const categoryDetails = rawCategoryDetails.map((category) => ({
    ...category,
    type: category.type === 'special' ? 'pcd' : category.type,
  }));

  const { SEAT_SELECTION_LIMIT } = useWhitelabelFeatures();

  useEffect(() => {
    if (passengers || passengerSelection) {
      /**
       * If passengers already exist, use them as initial
       */
      if (passengers.length > 0) {
        return;
      }

      /**
       * Create new passengers based on the passenger selection
       * Selection is an object containing the category as key and the number of passengers as value
       */
      const newPassengers = [];
      Object.entries(passengerSelection).forEach(([category, count]) => {
        let busCategory = category === 'pcd' ? 'special' : category;
        busCategory = category === 'adult' ? 'general' : category;
        for (let i = 0; i < count; i += 1) {
          newPassengers.push({
            ...PASSENGER_STRUCTURE,
            busCategory,
          });
        }
      });
      if (newPassengers.length > 0) {
        dispatch(setPassengers(newPassengers, token));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * This function updates the payment plans based on the price change of the passengers
   *
   * @param {*} newPassengers - New passengers array
   */
  const calculateDiffPayment = (newPassengers) => {
    if (!Array.isArray(newPassengers) || newPassengers.length === 0) return;

    const { total: newBaseTotal } = purchaseTotalByCategory(newPassengers, prices);
    if (!newBaseTotal || !total) return;

    const factor = newBaseTotal / total;
    if (Number.isNaN(factor) || factor <= 0) return;

    const newPaymentPlans = Object.entries(paymentPlans).reduce((acc, [key, plan]) => {
      acc[key] = Object.fromEntries(
        Object.entries(plan).map(([key, detail]) => {
          const newValue = Object.entries(detail).reduce((acc, [key, value]) => {
            acc[key] = Math.floor(value * factor * 100) / 100;
            return acc;
          }, {});
          return [key, newValue];
        }),
      );
      return acc;
    }, {});

    if (Object.keys(newPaymentPlans).length === 0) return;

    dispatch(updatePaymentPlans(newPaymentPlans));

    const selectedPlanCard = selectedInstallmentsPlan?.card;
    const selectedPlanMonths = selectedInstallmentsPlan?.months;
    if (!selectedPlanCard || !selectedPlanMonths) return;

    const newSelectedPlan = {
      ...selectedInstallmentsPlan,
      ...newPaymentPlans[selectedPlanCard]?.[selectedPlanMonths],
    };

    if (Object.keys(newSelectedPlan).length === 0) return;

    dispatch(selectInstallmentsPlan(newSelectedPlan));
  };

  /**
   * Checks if there is an adult passenger.
   */
  const hasAdultPassenger = () => {
    return passengers.some(
      (passenger) => passenger.busCategory !== 'minor' && passenger.busCategory !== 'student',
    );
  };

  /**
   * Adds a passenger to the status array
   * @param {String} type - Passenger category
   * @returns {Array} New passengers array
   */
  const addPassenger = (type) => {
    const newPassengers = [...passengers];

    if (!hasAdultPassenger() && type === 'minor') {
      return;
    }

    const newPassenger = {
      ...PASSENGER_STRUCTURE,
      busCategory: type === 'pcd' ? 'special' : type,
    };
    newPassengers.push(newPassenger);
    return newPassengers;
  };

  /**
   * Removes indicated passenger from status array
   * @param {String} type - Passenger category
   * @returns {Array} New passengers array
   */
  const clearPassenger = (type) => {
    if (passengers.length === 0) return;
    let newPassengers = [...passengers];

    const indexWithCategoryToRemove = newPassengers.findLastIndex(
      (passenger) =>
        passenger.busCategory === type || (type === 'pcd' && passenger.busCategory === 'special'),
    );
    if (indexWithCategoryToRemove !== -1 && newPassengers.length > 0) {
      newPassengers.splice(indexWithCategoryToRemove, 1);
    }

    const hasRemainingAdultPassenger = newPassengers.some(
      (passenger) => passenger.busCategory !== 'minor' && passenger.busCategory !== 'student',
    );

    if (!hasRemainingAdultPassenger) {
      newPassengers = newPassengers.filter((passenger) => passenger.busCategory !== 'minor');
    }

    if (newPassengers.length === 0) {
      return passengers;
    }

    return newPassengers;
  };

  /**
   * Handles passenger status update
   * Adds or removes a passenger from the status array
   * @param {string} type - Passenger category
   * @param {string} operation - Operation to perform (add or remove)
   * @returns {void}
   */
  const handlePassengerChange = (type, operation = 'add') => {
    let newPassengers = passengers;
    if (operation === 'add') {
      newPassengers = addPassenger(type);
    } else {
      newPassengers = clearPassenger(type);
    }

    // Deep compare passengers and newPassengers to avoid unnecessary updates
    if (JSON.stringify(passengers) === JSON.stringify(newPassengers)) return;

    dispatch(setPassengers(newPassengers, token));

    // Debounce the saveBulkPassengers action to avoid multiple calls
    // TODO update purchase total with backend updatePurchase(newPassengers);
    dispatch(updateBulkPurchaseTotal(newPassengers));
    calculateDiffPayment(newPassengers);
  };

  /**
   * Handles disabled status for specific category
   * checks if the category is available or if the limit has been reached
   */
  const handleDisabled = (type) => {
    if (passengers.length === SEAT_SELECTION_LIMIT) return true;

    const category = categoryDetails.find((busCategory) => busCategory.type === type);
    if (category?.availability === 0) return true;

    const currentPassengersOfType = passengers.filter(
      (passenger) =>
        passenger.busCategory === type || (type === 'pcd' && passenger.busCategory === 'special'),
    ).length;
    return currentPassengersOfType >= category?.availability;
  };

  const usableCategories = categoryDetails
    .filter((category) => Number(category.availability) !== 0)
    .map((category) => category.type);

  const loadingClass = isUpdating && 'animate-pulse filter blur-sm opacity-40 z-50';

  const notAvailableCategories = categoryDetails.filter(
    (category) => Number(category.availability) === 0,
  );

  return (
    <Spacing size="S" vertical>
      <Spacing alignItems="center" justifyContent="space-between">
        <Text size="L" weight="bold">
          {t('selector.who_are_traveling')}
        </Text>
        <Text mobileSize="S">{`${passengers.length} ${t('on_board')}`}</Text>
      </Spacing>

      <div className={`relative overflow-hidden rounded-[20px] bg-white/15 ${loadingClass}`}>
        <div className="relative z-[1] flex flex-col gap-[15px] p-[10px]">
          {usableCategories.map((type) => (
            <PassengerQuantitySelector
              key={type}
              type={type}
              counter={
                passengers.filter(
                  (passenger) =>
                    passenger.busCategory === type ||
                    (type === 'pcd' && passenger.busCategory === 'special'),
                ).length ?? 0
              }
              onClickIncrement={() => handlePassengerChange(type, 'add')}
              onClickDecrement={() => handlePassengerChange(type, 'remove')}
              isDisabled={handleDisabled(type)}
            />
          ))}

          <div className="max-w-[calc(100vw-50px)] overflow-x-auto md:overflow-visible">
            <Spacing size="S" alignItems="center">
              <Spacing size="XS" alignItems="center">
                <Icon type="BlockNotAvailable" size="S" />
                <Text mobileSize="S">
                  {t('selector.out_of_stock', {
                    count: notAvailableCategories.length,
                  })}
                </Text>
              </Spacing>
              <Box
                paddingHorizontal="S"
                paddingVertical="XS"
                bgColor="grayMedium"
                alphaBg="XS"
                borderRadius="L"
              >
                <Spacing justifyContent="center" alignItems="center">
                  {notAvailableCategories.map((category) => (
                    <Text key={category.type} color="grayMedium" weight="semibold">
                      {t(passengerTypes[category.type].label)}
                    </Text>
                  ))}
                </Spacing>
              </Box>
            </Spacing>
          </div>
        </div>

        <BlushingBackground hideMiddle />
      </div>
    </Spacing>
  );
}

export default PassengerSelectorBox;
