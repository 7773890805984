const availablePaymentEngines = {
  openPay: 'open_pay',
  adyen: 'adyen',
  mercadoPago: 'mercadopago',
  kueskiPay: 'kueski_pay',
  cielo: 'cielo',
  rede: 'rede',
};

export default availablePaymentEngines;
