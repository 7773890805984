import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CurrentLang } from 'utils/lang';
import { When } from 'react-if';
import { Text, FlatButton, Link, Modal, Spacing } from '@reservamos/elements';
import 'styles/components/overlay/PassengerPolicies';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import useWhitelabelCopies from 'hooks/whitelabel/useWhitelabelCopies';
import usePurchase from 'hooks/store/usePurchase';
import PoliciesContainer from 'ui/molecules/PoliciesContainer';

/**
 * PassengerPolicies component.
 * @param {object} props - location, isCheckbox
 * @param {string} props.location - Location identifier to determine display context ('checkout' or other)
 * @param {boolean} props.isCheckbox - Whether to render in checkbox context with smaller text
 */
const PassengerPolicies = ({ location, isCheckbox }) => {
  const features = useWhitelabelFeatures();
  const copies = useWhitelabelCopies();
  const { isDotersUser, restrictionCodes, bulkTicket: isBulkTicket } = usePurchase();
  const { t } = useTranslation(['general', 'seats', 'purchase']);
  const lang = CurrentLang();

  const {
    underagePolicies = [],
    termsAndConditionsUrl,
    noticeOfPrivacy,
    luggagePolicies,
    petsPolicies,
    travelWithUnderagePolicies,
    travelPolicies,
  } = copies.policies[lang] || {};

  const messageContext = termsAndConditionsUrl ? null : 'female';

  const textSize = isCheckbox ? 'S' : 'XS';

  if (!features.PASSENGERS_TERMS_AND_CONDITIONS_ENABLED) return null;

  /**
   * Renders a Link component if the condition is met
   * @param {boolean} condition - Determines if the link should be rendered
   * @param {string} text - Text to display in the link
   * @param {string} href - URL for the link
   * @returns {React.ReactElement|null} Link component or null
   */
  const renderLink = (condition, text, href) =>
    condition ? (
      <Link text={text} href={href} size={textSize} type="accent" weight="regular" newTab />
    ) : null;

  // TODO  Refactor this component to ui/molecules to use it in other components
  // Places we can use it: PassengerPolicies.js, DownloadTickets.js
  return (
    <>
      <div className={isCheckbox ? '' : 'passenger-policies-container'}>
        <PoliciesContainer location={location} textSize={textSize}>
          <When condition={Boolean(features.SHOW_POLICIES_CONTAINER_ALERT || isBulkTicket)}>
            {features.DOTERS_NO_CANCELLATION_NOTICE && isDotersUser && (
              <Spacing size="XS">
                <Text size="S" color="grayMedium">
                  • {`${t('seats:passenger_policy.doters_no_cancelable')} `}
                  <Text elementType="span" size="S">
                    {t('seats:passenger_policy.when_using_doters').toLowerCase()}
                  </Text>
                </Text>
              </Spacing>
            )}
            {isBulkTicket && (
              <Text size="S" color="grayMedium" elementType="span">
                &#8226; {t('seats:passenger_policy.availability_subject')}{' '}
                <Text size="S" weight="bold" elementType="span">
                  {t('seats:passenger_policy.availability_subject_recommendation')}
                </Text>
              </Text>
            )}
            <Text size="S" color="grayMedium">
              &#8226; {t('seats:passenger_policy.you_are_acepting')}{' '}
              <When condition={Boolean(termsAndConditionsUrl)}>
                <Link
                  text={t('seats:passenger_policy.terms_and_conditions')}
                  href={termsAndConditionsUrl}
                  size="S"
                  type="accent"
                  weight="regular"
                  newTab
                />
                . {t('seats:passenger_policy.you_can_check_our')}{' '}
                <Link
                  text={t('seats:passenger_policy.here')}
                  href={noticeOfPrivacy?.url}
                  size="S"
                  type="accent"
                  weight="regular"
                  newTab
                />
              </When>
            </Text>
          </When>

          <When condition={Boolean(!features.SHOW_POLICIES_CONTAINER_ALERT && !isBulkTicket)}>
            {t('seats:passenger_policy.message', { context: messageContext })}
            {renderLink(
              Boolean(termsAndConditionsUrl),
              t('seats:passenger_policy.terms_and_conditions'),
              termsAndConditionsUrl,
            )}
            <When condition={Boolean(luggagePolicies)}>
              {termsAndConditionsUrl && t('seats:passenger_policy.comma_separator')}
              {renderLink(Boolean(luggagePolicies), luggagePolicies?.label, luggagePolicies?.url)}
            </When>
            <When condition={Boolean(petsPolicies)}>
              {luggagePolicies && t('seats:passenger_policy.comma_separator')}
              {renderLink(Boolean(petsPolicies), petsPolicies?.label, petsPolicies?.url)}
            </When>
            <When condition={Boolean(travelWithUnderagePolicies)}>
              {petsPolicies && t('seats:passenger_policy.comma_separator')}
              {renderLink(
                Boolean(travelWithUnderagePolicies),
                travelWithUnderagePolicies?.label,
                travelWithUnderagePolicies?.url,
              )}
            </When>
            {underagePolicies.length > 0
              ? t('seats:passenger_policy.comma_separator')
              : t('seats:passenger_policy.and_separator')}
            {renderLink(
              Boolean(noticeOfPrivacy),
              noticeOfPrivacy?.label || t('seats:passenger_policy.privacy_notice'),
              noticeOfPrivacy?.url,
            )}

            <When condition={Boolean(travelPolicies)}>
              {t('seats:policies_separator')}
              {renderLink(
                Boolean(travelPolicies),
                travelPolicies?.label || t('seats:passenger_policy.travel_policies'),
                travelPolicies?.url,
              )}
            </When>
            {underagePolicies.length > 0 && (
              <>
                {t('seats:policies_separator')}
                <Modal
                  headerColor="primary"
                  title={t('general:policies_title')}
                  modalTrigger={
                    <FlatButton size="XS" weight="regular">
                      {t('seats:see_policies')}
                    </FlatButton>
                  }
                >
                  <Spacing vertical>
                    {Boolean(underagePolicies.length) &&
                      underagePolicies.map((item) => (
                        <Text key={item} size="S">
                          <b>·</b>
                          {` ${item}`}
                        </Text>
                      ))}
                  </Spacing>
                </Modal>
              </>
            )}
            <When
              condition={Boolean(
                restrictionCodes && restrictionCodes.length && !features.BULK_TICKET_ENABLED,
              )}
            >
              {() => (
                <>
                  <Spacing size="XS">
                    <Text size={textSize} color="grayMedium">
                      {t('purchase:restrictions.title', { count: restrictionCodes?.length })}:
                    </Text>
                  </Spacing>
                  {restrictionCodes?.map((restriction) => (
                    <Spacing size="XS">
                      <Text key={restriction} size={textSize} color="grayMedium">
                        • {t(`purchase:restrictions.${restriction}`)}.
                      </Text>
                    </Spacing>
                  ))}
                </>
              )}
            </When>
            {features.DOTERS_ENABLED ? `; ${t('seats:passenger_policy.doters')}` : '.'}
          </When>
          {features.SHOW_PASSENGER_AUTOFILL_REMINDER &&
            t('seats:passenger_policy.auto_fill_reminder')}
        </PoliciesContainer>
      </div>
    </>
  );
};

PassengerPolicies.propTypes = {
  isCheckbox: PropTypes.bool,
  location: PropTypes.string,
};

PassengerPolicies.defaultProps = {
  isCheckbox: false,
};

export default PassengerPolicies;
