import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import PurchasePricingPassengerTrip from 'components/purchase/PurchasePricingPassengerTrip';
import PurchasePricingTrip from 'components/purchase/PurchasePricingTrip';
import PurchasePricingExtras from 'components/purchase/PurchasePricingExtras';
import PurchasePricingDiscount from 'components/purchase/PurchasePricingDiscount';
import DetailsTitle from 'components/DetailsTitle';
import TransferNotice from 'components/TransferNotice';
import usePricingBeforeCheckout from 'hooks/usePricingBeforeCheckout';
import { useTranslation } from 'react-i18next';
import PurchasePricingBulk from 'components/purchase/PurchasePricingBulk';
import { purchaseTotalByCategory } from 'utils/Reserbus';
import getPurchaseStepName from '../../../utils/purchase/getPurchaseStepName';
import 'styles/components/purchase/PurchasePricing';
import PurchaseFares from '../PurchaserForm/PurchaseFares';

const propTypes = {
  departs: PropTypes.object.isRequired,
  extras: PropTypes.shape({
    carbon: PropTypes.number,
    total: PropTypes.number.isRequired,
    installmentsFee: PropTypes.number.isRequired,
    insurance: PropTypes.number.isRequired,
    markup: PropTypes.number.isRequired,
  }).isRequired,
  hasMixedTrips: PropTypes.bool.isRequired,
  passengers: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  passengerCount: PropTypes.number.isRequired,
  titleFooterDescription: PropTypes.string.isRequired,
  titleHeaderDescription: PropTypes.string.isRequired,
  titleTotal: PropTypes.number.isRequired,
  returns: PropTypes.object.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  externalCouponCode: PropTypes.string,
  selectedInstallmentsPlan: PropTypes.string.isRequired,
  monthlySelectedPlan: PropTypes.string.isRequired,
  exchangedTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedPaymentOption: PropTypes.shape({
    months: PropTypes.number.isRequired,
  }),
  departsFares: PropTypes.arrayOf(PropTypes.object),
  returnsFares: PropTypes.arrayOf(PropTypes.object),
  isExchange: PropTypes.bool,
  isBulkTicket: PropTypes.bool,
};

const PurchasePricing = ({
  departs,
  extras,
  hasMixedTrips,
  passengers,
  passengerCount,
  titleFooterDescription,
  titleHeaderDescription,
  titleTotal,
  returns,
  roundTrip,
  externalCouponCode,
  selectedInstallmentsPlan,
  monthlySelectedPlan,
  selectedPaymentOption,
  departsFares,
  returnsFares,
  isExchange,
  exchangedTotal,
  isBulkTicket,
}) => {
  const {
    passengersCount: passengerCountBeforeCheckout,
    purchaseTotal: purchaseTotalBeforeCheckout,
    departTripPricing: departTripPricingBeforeCheckout,
    returnTripPricing: returnTripPricingBeforeCheckout,
    selectedSeats,
    displayPricingBeforeCheckout,
  } = usePricingBeforeCheckout({ isRoundTrip: roundTrip });

  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const location = useLocation();
  const { t } = useTranslation('purchase');
  const showTotalAtBottom = features.TOTAL_AT_THE_BOTTOM;
  const purchaseStep = getPurchaseStepName(location.pathname);

  if (!['checkout', 'complete'].includes(purchaseStep) && !displayPricingBeforeCheckout)
    return null;

  let detailsTitleHeader = '';

  if (selectedPaymentOption === 'credit_card' && monthlySelectedPlan > 1) {
    detailsTitleHeader = t('label.instalments_title', {
      number: selectedInstallmentsPlan.months,
    });
  } else {
    detailsTitleHeader = t(`label.${titleHeaderDescription}`);
  }

  const exchangeTripTextBrand = {
    gfa: 'your_trip_gfa',
    default: 'your_trip',
  };

  const simpleTextBrand = {
    gfa: 'your_trip_web_gfa',
    default: 'your_trip',
  };

  const simpleTripPriceText = isExchange
    ? exchangeTripTextBrand[env.brand] || exchangeTripTextBrand.default
    : simpleTextBrand[env.brand] || simpleTextBrand.default;
  const departsTotalLabel = roundTrip ? 'your_one_way_trip' : simpleTripPriceText;
  const returnsTotalLabel = 'your_return_trip';

  // TODO Bulk pricing is based only on departures right now
  const departsPrices = departs.fragments[0].prices;
  const {
    total: globalBulkTotal,
    taxes: globalBulkTaxes,
    totalsPerCategory,
  } = purchaseTotalByCategory(passengers, departsPrices);

  /**
   * Calculates the total price based on ticket type and checkout state
   * @returns {number} The calculated total price
   */
  const getTotal = () => {
    if (isBulkTicket) {
      if (monthlySelectedPlan > 1) {
        return selectedInstallmentsPlan?.monthlyPayment;
      }
      return globalBulkTotal;
    }
    if (displayPricingBeforeCheckout) {
      return purchaseTotalBeforeCheckout;
    }
    return titleTotal;
  };

  const detailsTitleComponent = (classModifier) => (
    <DetailsTitle
      title="purchase_details"
      classModifier={classModifier}
      headerDescription={detailsTitleHeader}
      footerDescription={titleFooterDescription}
      total={getTotal()}
    />
  );

  return (
    <div className="purchase-pricing">
      {showTotalAtBottom ? null : detailsTitleComponent('-top')}
      {hasMixedTrips ? <TransferNotice /> : null}
      {isBulkTicket ? (
        <PurchasePricingBulk
          totalsPerCategory={totalsPerCategory}
          globalTotal={globalBulkTotal}
          globalTaxes={globalBulkTaxes}
          labelTaxes={t('extra_price')}
          labelTotal={t('label.total')}
        />
      ) : (
        <div>
          {features.TRIP_PRICING_BY_PASSENGER ? (
            <PurchasePricingPassengerTrip
              reservation={departs}
              passengers={passengers}
              passengerCount={passengerCount}
              totalLabel={departsTotalLabel}
              externalCouponCode={externalCouponCode}
              selectedSeatsBeforeCheckout={selectedSeats.departs}
              displayPricingBeforeCheckout={displayPricingBeforeCheckout}
            />
          ) : (
            <PurchasePricingTrip
              reservation={departs}
              passengers={passengers}
              passengerCount={passengerCount}
              totalLabel={departsTotalLabel}
              displayPricingBeforeCheckout={displayPricingBeforeCheckout}
              passengersCountBeforeCheckout={passengerCountBeforeCheckout}
              pricingBeforeCheckout={departTripPricingBeforeCheckout}
              exchangedTotal={exchangedTotal}
            />
          )}

          {roundTrip ? (
            <>
              {features.TRIP_PRICING_BY_PASSENGER ? (
                <PurchasePricingPassengerTrip
                  reservation={returns}
                  passengers={passengers}
                  passengerCount={passengerCount}
                  totalLabel={returnsTotalLabel}
                  externalCouponCode={externalCouponCode}
                  selectedSeatsBeforeCheckout={selectedSeats.returns}
                  displayPricingBeforeCheckout={displayPricingBeforeCheckout}
                />
              ) : (
                <PurchasePricingTrip
                  reservation={returns}
                  passengers={passengers}
                  passengerCount={passengerCount}
                  totalLabel={returnsTotalLabel}
                  displayPricingBeforeCheckout={displayPricingBeforeCheckout}
                  passengersCountBeforeCheckout={passengerCountBeforeCheckout}
                  pricingBeforeCheckout={returnTripPricingBeforeCheckout}
                />
              )}
            </>
          ) : null}
        </div>
      )}

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <PurchasePricingExtras {...extras} />
      <PurchasePricingDiscount />
      {features.SELECTABLE_FARE && (
        <PurchaseFares departsFares={departsFares} returnsFares={returnsFares} />
      )}
      {showTotalAtBottom ? detailsTitleComponent('-bottom') : null}
    </div>
  );
};

PurchasePricing.propTypes = propTypes;

export default PurchasePricing;
