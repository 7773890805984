import { camelizeKeys, decamelizeKeys } from 'humps';
import { getDistinctId } from 'user-analytics';
//  This module is legacy code and is not exported in the core section
import { core as coreLegacy } from 'js-api-client';
import coreApi from 'js-api-client/core';
import { transformIdentifierObject } from 'utils/userPreferences';
import userFingerprint from '../userFingerprint';

/**
 * UserPreferencesService is a service for managing user preferences.
 */
const UserPreferencesService = () => {
  /**
   * Retrieves recommended trips based on the search ID.
   * @param {Object} options - The options for retrieving recommended trips.
   * @param {string} options.searchId - The search ID.
   * @returns {Promise} A promise that resolves with the recommended trips.
   * @throws {Error} If no distinct ID is available.
   */
  const getRecommendedTrips = ({ searchId }) => {
    return new Promise((resolve, reject) => {
      const userFingerprintId = userFingerprint.getFingerprint();
      if (!userFingerprintId) return reject(new Error('No fingerprint id'));

      coreApi.recommendations
        .getRecommendedTrips({ searchId, userIdentifier: userFingerprintId })
        .then((response) => resolve(camelizeKeys(response)))
        .catch((error) => reject(error));
    });
  };

  /**
   * Creates trip price alerts.
   * @param {Object} payload - The payload for creating trip price alerts.
   * @param {number} payload.seen_price - Price showed to the user previously.
   * @param {string} payload.origin - origin slug
   * @param {string} payload.destination - destination slug
   * @param {string} payload.departure_date - date trip departure
   * @param {string} payload.email - require email user
   * @param {string} payload.phone -  phone number user
   * @param {string} payload.user_fingerprint - id fingerprint
   * @param {string} payload.tracker_id - distinct id mixpanel
   * @returns {Promise} A promise that resolves with the created trip price alerts.
   * @throws {Error} If no user fingerprint or distinct ID is available.
   */
  const createTripPriceAlerts = (payload) => {
    return new Promise((resolve, reject) => {
      const userFingerprintId = userFingerprint.getFingerprint();
      const distinctId = getDistinctId();
      if (!distinctId) return reject(new Error(`No distinct id`));
      if (userFingerprintId) payload.userFingerprint = userFingerprintId;
      coreLegacy
        .createTripPriceAlerts({
          tracker_id: distinctId,
          ...decamelizeKeys(payload),
        })
        .then((response) => resolve(camelizeKeys(response)))
        .catch((error) => reject(error));
    });
  };

  /**
   * Creates an anonymous user profile.
   * @param {Object} payload - The payload containing user information.
   * @param {string} [payload.email] - The user's email.
   * @param {string} [payload.phone] - The user's phone number.
   * @returns {Promise} A promise that resolves with the created profile.
   */
  const createAnonymousProfile = (payload) => {
    const identifiers = [];
    const userFingerprintId = userFingerprint.getFingerprint();
    const distinctId = getDistinctId();

    if (userFingerprintId) identifiers.push({ key: 'fingerprint_id', value: userFingerprintId });
    if (distinctId) identifiers.push({ key: 'distinct_id', value: distinctId });

    const dataPayload = transformIdentifierObject(payload);

    return new Promise((resolve, reject) => {
      coreApi.profiles
        .createAnonymousProfile(decamelizeKeys({ ...dataPayload, identifiers }))
        .then((response) => resolve(camelizeKeys(response)))
        .catch((error) => reject(error));
    });
  };

  /**
   * Retrieves frequent passengers associated with the user's fingerprint.
   * @returns {Promise} A promise that resolves with the list of frequent passengers.
   * @throws {Error} If no fingerprint ID is available.
   */
  const getFrequentPassengers = async () => {
    const userFingerprintId = userFingerprint.getFingerprint();
    if (!userFingerprintId) throw new Error('No fingerprint id');
    const response = await coreApi.recommendations.getFrequentPassengers(userFingerprintId);
    return camelizeKeys(response);
  };

  /**
   * Deletes a frequent passenger from the user's profile.
   * @param {string} passengerId - The ID of the passenger to delete.
   * @returns {Promise<boolean>} A promise that resolves to true when deletion is successful.
   * @throws {Error} If no fingerprint id is available.
   */
  const deleteFrequentPassenger = async (passengerId) => {
    const userFingerprintId = userFingerprint.getFingerprint();
    if (!userFingerprintId) throw new Error('No fingerprint id');
    coreApi.recommendations.deleteFrequentPassenger(userFingerprintId, passengerId);
    return true;
  };

  return {
    getRecommendedTrips,
    createTripPriceAlerts,
    createAnonymousProfile,
    getFrequentPassengers,
    deleteFrequentPassenger,
  };
};

const userPreferencesService = UserPreferencesService();
export default userPreferencesService;
