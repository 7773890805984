import { useSelector } from 'react-redux';

/**
 * Custom hook to access user preferences from Redux store
 * @returns {object} User preferences - frequentPassengers
 * @returns {Array} returns.frequentPassengers - List of frequent passengers
 */
const useUserPreferences = () => {
  const { frequentPassengers } = useSelector((state) => state.userPreferences.toJS());

  return {
    passengers: frequentPassengers.passengers,
  };
};

export default useUserPreferences;
